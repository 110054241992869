import env from '~/src/utils/env';

export type QueryParams = {
  search: string;
  sort_by: string;
  order: 'desc' | 'asc';
  limit: number;
  matter: string | number;
  search_text: string;
};

export const getRelatedQuestionnaireSubmissionsByMatterIdUrl = (
  orgFprint: string,
  matterId: string | number,
) => {
  return `${env.apiUrl}/clients/api/v2/org/${orgFprint}/related-questionnaire-submission/?matter_id=${matterId}`;
};

export const getRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionIdUrl =
  (orgFprint: string, questionnaireSubmissionId: string | number) => {
    return `${env.apiUrl}/clients/api/v2/org/${orgFprint}/related-questionnaire-submission/?questionnaire_submission_id=${questionnaireSubmissionId}`;
  };

export const getRelatedQuestionnaireSubmissionUnmappedQuestionsUrl = (
  orgFprint: string,
  matterId: string | number,
) => {
  return `${env.apiUrl}/clients/api/v2/org/${orgFprint}/related-questionnaire-submission-question/?matter_id=${matterId}`;
};

import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import cookieUtil from '~/src/utils/cookies';

export const LOGOUT_URL = `${env.apiUrl}/api/v2/account/logout/`;

const fetchLogout = async () => {
  const response = await fetch(LOGOUT_URL, { method: 'DELETE' });
  const data = await response.json();
  return data;
};
const onSettled = async (data: any) => {
  cookieUtil.logout();
  const identityUrl: string = data?.identity_logout_url;
  if (identityUrl) {
    window.location.assign(identityUrl);
  } else {
    window.location.assign('/signin');
  }
};

export const doLogout = async () => {
  let response;
  try {
    response = await fetchLogout();
  } catch {
    // pass
  } finally {
    onSettled(response);
  }
};

export const useLogout = () =>
  useMutation({ mutationFn: fetchLogout, onSettled });

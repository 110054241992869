import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  label: {
    display: 'block',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
    marginBottom: theme.unit * 3,
    ':last-child': {
      marginBottom: 0,
    },
  },
  staticTextPlaceholder: {
    color: theme.colors.chrome,
  },
  labelIcon: {
    left: `-${theme.unit * 2}px`,
    position: 'absolute',
  },
  labelRequiredIcon: {
    color: theme.colors.paleTomato,
  },
  labelInline: {
    display: 'inline-block',
    marginRight: theme.unit * 2,
    verticalAlign: 'top',
    maxWidth: '180px',
    ':last-child': {
      marginRight: 0,
    },
  },
  labelNoMargin: {
    display: 'block',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
  },
  labelTitle: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    marginBottom: theme.unit * 1,
  },
  labelHint: {
    display: 'block',
    fontSize: theme.fonts.size.extraSmall,
    color: theme.colors.chrome,
    maxWidth: '100%',
    marginBottom: theme.unit * 1,
    lineHeight: '1.5em',
  },
  labelCount: {
    display: 'block',
    fontSize: theme.fonts.size.extraSmall,
    color: theme.colors.chrome,
    marginTop: theme.unit * 1,
  },
  labelError: {
    display: 'block',
    fontSize: theme.fonts.size.small,
    color: theme.colors.paleTomato,
    marginTop: theme.unit * 1,
    whiteSpace: 'pre-line',
  },
  textInputLabel: {
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    position: 'relative',
    boxSizing: 'border-box',
    display: 'block',
    opacity: '1',
    border: theme.border,
    borderRadius: theme.borderRadius,
    transition: 'opacity 0.2s ease',
    ':hover': {
      opacity: '1',
    },
    ':focus': {
      opacity: '1',
    },
  },

  textInputLabelCountText: {
    display: 'block',
    position: 'absolute',
    top: theme.unit * 5,
    fontSize: theme.fonts.size.extraSmall,
    color: theme.colors.chrome,
  },
  textInputLabelStatic: {
    opacity: 1,
    border: 'none',
    paddingLeft: 0,
    lineHeight: '1.25em',
  },
  textInputLabelError: {
    borderColor: theme.colors.paleTomato,
    whiteSpace: 'pre-line',
  },
  textInputLabelFocus: {
    border: theme.border,
    borderColor: theme.colors.bluebird,
  },
  textInputLabelFocusError: {
    borderColor: theme.colors.paleTomato,
  },
  textInput: {
    boxSizing: 'border-box',
    width: '100%',
    border: 'none',
    fontSize: theme.fonts.size.small,
    background: theme.colors.surface,
    padding: theme.unit,
    borderRadius: theme.borderRadius,
    ':active': {
      outline: 'none',
    },
    ':focus': {
      outline: 'none',
    },
  },
  textInputRightIcon: {
    paddingRight: theme.unit * 4,
  },
  textInputLeftIcon: {
    paddingLeft: theme.unit * 4,
  },
  textInputError: {
    color: theme.colors.paleTomato,
  },
  leftIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: theme.unit,
    display: 'flex',
    alignItems: 'center',
  },
  rightIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: theme.unit,
    display: 'flex',
    alignItems: 'center',
  },
  textInputStatic: {
    padding: 0,
  },
  textInputPlaceholder: {
    color: theme.colors.chrome,
  },

  /* Select */
  selectInputContainer: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: theme.unit * 3,
  },
  selectNoOption: {
    fontSize: theme.fonts.size.extraSmall,
    color: theme.colors.chrome,
    padding: `0px ${theme.unit * 3}px`,
    marginTop: `${theme.unit * 3}px`,
  },

  selectOption: {
    fontSize: theme.fonts.size.extraSmall,
    color: theme.colors.charcoal,
    padding: `${theme.unit * 1.25}px ${theme.unit * 2}px`,
    border: `1px solid transparent`,

    transition: 'background 0.2s ease',

    ':hover': {
      cursor: 'pointer',
    },
  },

  selectOptionFocused: {
    outline: 'none',
    background: theme.colors.pattensBlue,
  },

  selectOptionSelected: {
    cursor: 'pointer',
    background: theme.colors.babyBlue,
    color: theme.colors.charcoal,
  },

  /* Static Link */
  staticLink: {
    ':focus': {
      outline: 'none',
      borderBottom: `solid 1px ${theme.colors.bluebird}`,
    },
    ':active': {
      outline: 'none',
      borderBottom: `solid 1px ${theme.colors.bluebird}`,
    },
  },

  staticLinkDisabled: {
    color: theme.colors.chrome,
    cursor: 'not-allowed',
  },

  /* Copy Link */
  copyLink: {
    overflow: 'auto',
    border: theme.border,
    borderColor: theme.colors.smoke,
    color: theme.colors.bluebird,
    background: theme.colors.white,
    zIndex: 1,
    padding: theme.unit,
    borderRadius: theme.borderRadius,
    display: 'block',
    paddingRight: '43px',

    ':focus': {
      outline: 'none',
      borderColor: theme.colors.bluebird,
      borderBottom: `solid 1px ${theme.colors.bluebird}`,
    },
    ':active': {
      outline: 'none',
      borderColor: theme.colors.bluebird,
      borderBottom: `solid 1px ${theme.colors.bluebird}`,
    },
  },

  copyLinkIcon: {
    background: theme.colors.white,
    marginRight: '1px',
    borderRadius: theme.borderRadius,
    position: 'absolute',
    top: 1,
    right: 0,
    padding: theme.unit,
    borderLeft: theme.border,
    ':hover': {
      cursor: 'pointer',
    },
  },

  copyLinkDisabled: {
    color: theme.colors.smoke,
    borderColor: theme.colors.smoke,
    cursor: 'not-allowed',
  },

  /* Populate Multiple Choice */
  multipleChoiceFieldsetTextAreaContainer: {
    position: 'relative',
    overflow: 'visible',
  },
  multipleChoiceFieldsetEditedLabel: {
    border: theme.border,
    borderColor: theme.colors.bluebird,
    color: theme.colors.bluebird,
    background: theme.colors.white,
    zIndex: 1,
    padding: theme.unit / 2,
    borderRadius: theme.borderRadius,
    position: 'absolute',
    right: '10%',
    bottom: '-1em',
  },
  multipleChoiceFieldsetBreak: {
    height: theme.unit * 4,
    marginLeft: theme.unit * 2,
    width: '1px',
    background: theme.colors.smoke,
  },
  populateMultipleChoiceInputLabelChecked: {
    border: theme.border,
    borderColor: theme.colors.bluebird,
    background: 'rgba(24, 143, 249, .06)',
    ':last-of-type': {
      borderBottomColor: theme.colors.bluebird,
    },
  },
  populateMultipleChoiceInputLabel: {
    display: 'block',
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    position: 'relative',
    boxSizing: 'border-box',
    opacity: '1',
    border: theme.border,
    borderBottom: 'none',
    padding: theme.unit * 1,
    transition: 'opacity 0.2s ease',
    ':hover': {
      opacity: '1',
      cursor: 'pointer',
    },
    ':focus': {
      opacity: '1',
      cursor: 'pointer',
    },
    ':first-of-type': {
      borderTopLeftRadius: theme.borderRadius,
      borderTopRightRadius: theme.borderRadius,
    },
    ':last-of-type': {
      borderBottomLeftRadius: theme.borderRadius,
      borderBottomRightRadius: theme.borderRadius,
      borderBottom: theme.border,
    },
  },
  populateMultipleChoiceInput: {
    marginRight: theme.unit,
  },
  multipleChoiceCheckboxInput: {
    marginRight: theme.unit,
  },
  /* Multiple Choice */
  multipleChoiceLabelText: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    position: 'relative',
    boxSizing: 'border-box',
    marginTop: theme.unit * 3,
    marginBottom: theme.unit * 2,
  },
  multipleChoiceInputLabel: {
    display: 'block',
    color: theme.colors.charcoal,
  },
  multipleChoiceInput: {
    marginRight: theme.unit,
    marginBottom: theme.unit,
  },
  multipleChoiceFieldset: {},
  /* Incrementer */
  incrementerContainer: {
    display: 'flex',
  },
  incrementerButton: {
    padding: theme.unit * 1.5,
    border: theme.border,
    borderRadius: theme.borderRadius,
    transition: 'borderColor 250ms ease',
    ':hover': {
      borderColor: theme.colors.bluebird,
    },
  },
  incrementerAddButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  incrementerSubtractButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  incrementerValue: {
    userSelect: 'none',
    padding: `${theme.unit * 1.5}px ${theme.unit * 2}px`,
    borderTop: theme.border,
    borderBottom: theme.border,
  },

  duplicateErrorInput: {
    backgroundColor: '#FEF2F2',
    border: '1px solid #DC2626',
  },

  renameErrorInput: {
    backgroundColor: '#FEF2F2',
    border: '1px solid #DC2626',
    minWidth: '567px',
    width: '100%',
  },

  renameStandardInput: {
    boxSizing: 'border-box',
    minWidth: '567px',
    width: '100%',
    border: 'none',
    fontSize: theme.fonts.size.small,
    background: theme.colors.surface,
    padding: theme.unit,
    borderRadius: theme.borderRadius,
    ':active': {
      outline: 'none',
    },
    ':focus': {
      outline: 'none',
    },
  },
});

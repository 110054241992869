import React from 'react';
import { css } from 'aphrodite';
import { Card, CardTitle } from '~/src/components/Card';
import Spinner from '~/src/components/Spinner';

import styles from '../styles';
import { UpdatePaymentMethodButton } from '../UpdatePaymentMethodButton';

export const SubscriptionDetails = ({ pendingRequest }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      className={css(styles.settingsCardContainer)}
    >
      <Card elevate>
        <CardTitle bold>Payment Details</CardTitle>
        <div className={css(styles.settingsRow)}>
          <UpdatePaymentMethodButton />
        </div>
        {pendingRequest && (
          <div className="absolute flex items-center justify-center -inset-8 bg-opacity-50 bg-gray-100">
            <Spinner />
          </div>
        )}
      </Card>
    </div>
  );
};

import React from 'react';
import { getFormattedDateFromIsoString } from '~/src/utils/date';
import { Box, Typography } from '@mui/material';
import {
  CardListItemWithActions,
  CardListItemMetaText,
} from '~/src/components/CardList';
import { Type, Trash, MoreVertical } from 'react-feather';
import { QuestionnaireStatusBadge } from '../QuestionnaireStatusBadge';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { ReactComponent as NavQuestionnairesIcon } from '~/src/static/nav-questionnaires-black.svg';
import { QuestionnaireSubmissionMetadataRow } from './QuestionnaireSubmissionMetadataRow';
import { useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId } from '~/src/entities/questionnairesV2/hooks/useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId';
import { RelatedQuestionnaireSubmission } from '~/src/entities/questionnairesV2/types';

export const QuestionnaireSubmissionTableCardListItem: React.FC<{
  submission: QuestionnaireSubmission;
  onRename: (
    submission: QuestionnaireSubmission,
    relatedQuestionnaireSubmissions:
      | RelatedQuestionnaireSubmission[]
      | undefined,
  ) => void;
  onDelete: (
    submission: QuestionnaireSubmission,
    relatedQuestionnaireSubmissions:
      | RelatedQuestionnaireSubmission[]
      | undefined,
  ) => void;
  onViewDetails: (submission: QuestionnaireSubmission) => void;
}> = ({ submission, onRename, onDelete, onViewDetails }) => {
  const { data: relatedQuestionnaireSubmissions } =
    useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId(
      submission.id,
    );

  const actions = [
    {
      label: 'Rename',
      icon: <Type size={16} />,
      onClick: () => {
        onRename(
          submission,
          relatedQuestionnaireSubmissions as
            | RelatedQuestionnaireSubmission[]
            | undefined,
        );
      },
    },
    {
      label: 'Delete',
      icon: <Trash size={16} />,
      onClick: () => {
        onDelete(
          submission,
          relatedQuestionnaireSubmissions as
            | RelatedQuestionnaireSubmission[]
            | undefined,
        );
      },
    },
  ];

  return (
    <Box pb={1} key={submission.id}>
      <CardListItemWithActions
        key={submission.id}
        onClick={() => onViewDetails(submission)}
        title={{
          label: (
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <NavQuestionnairesIcon />
              <Typography variant="button">{submission.title}</Typography>
            </Box>
          ),
          meta: [
            <QuestionnaireSubmissionMetadataRow
              key={'metadata'}
              matterId={submission.target_identifier}
              questionnaireId={String(submission.questionnaire_id)}
            />,
          ],
        }}
        meta={
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <QuestionnaireStatusBadge status={submission.status} />
            <CardListItemMetaText>
              Last updated:{' '}
              {getFormattedDateFromIsoString(submission.updated_at ?? '')}
            </CardListItemMetaText>
            <MoreVertical size={16} />
          </Box>
        }
        actions={{
          actions,
          primary: {
            label: 'View live questionnaire',
            variant: 'outlined',
            disabled: false,
            onClick: () => onViewDetails(submission),
          },
        }}
      />
    </Box>
  );
};

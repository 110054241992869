/* Hooks */
import useMst from '~/src/hooks/useMst';

/* Configurations */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { useLayoutContext } from '../contexts/Layout';

const useTemplatesActions = () => {
  const { showSlideIn, hideModal, showModal } = useLayoutContext();

  const {
    selectTemplate,
    deleteTemplate,
    updateTemplate,
    addTemplateToSeletionSidebar,
    fetchChildrenTemplates,
  } = useMst((store) => ({
    selectTemplate: store.templates.selectTemplate,
    deleteTemplate: store.templates.deleteTemplate,
    updateTemplate: store.templates.updateTemplate,
    addTemplateToSeletionSidebar: store.templates.addTemplateToSeletionSidebar,
    fetchChildrenTemplates: store.templates.fetchChildrenTemplates,
  }));

  const handlePreviewTemplate = (template, hideAddToSelection = false) => {
    showSlideIn(LAYOUT_SLIDEIN_TYPES.documentPreview, {
      largeSlideIn: true,
      templateObj: template,
      templateId: template.id,
      hideAddToSelection,
    });
  };
  const handleRenameTemplate = (template) => {
    const handleRenameConfirm = async (form) => {
      const { value } = form.fields.name;
      await updateTemplate(template.id, { name: value });
      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Template',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: template.title,
          validation: VALIDATION_TYPES.templateName,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };
  const handleDeleteTemplate = (template) => {
    const handleDeleteConfirm = async () => {
      deleteTemplate(template.id);
      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete Template ${template.title}?`,
      message: `This will permanently delete the template from your organization. This action cannot be undone.`,
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
    });
  };
  const handleSelectTemplate = (templateId) => {
    selectTemplate(templateId);
  };

  const handleAddTemplateToSeletionSidebar = (template) => {
    addTemplateToSeletionSidebar(template);
  };

  const handleFetchChildrenTemplates = async (templateIds) => {
    await fetchChildrenTemplates(templateIds);
  };

  return {
    previewTemplate: handlePreviewTemplate,
    renameTemplate: handleRenameTemplate,
    deleteTemplate: handleDeleteTemplate,
    selectTemplate: handleSelectTemplate,
    addTemplateToSeletionSidebar: handleAddTemplateToSeletionSidebar,
    fetchChildrenTemplates: handleFetchChildrenTemplates,
  };
};

export default useTemplatesActions;

import { useEffect, useState } from 'react';
import matterService from '~/src/services/matter';
import { Matter } from '~/src/stores/matterStore';
import { Instance } from 'mobx-state-tree';
import { createPrepopulateRecord } from '../utils';
import { useClioQuestionnaireSubmissionById } from './useClioQuestionnaireSubmissionById';
import { useUpdateQuestionnaireSubmission } from './useUpdateQuestionnaireSubmission';

export const usePrepopulateQuestionnaireResponses = (orgFprint: string) => {
  const [matterData, setMatterData] =
    useState<Instance<typeof Matter | null>>(null);
  const [submissionId, setSubmissionId] = useState<string | undefined>(
    undefined,
  );
  const [matterId, setMatterId] = useState<string | undefined>(undefined);
  const [isMatterError, setIsMatterError] = useState(false);
  const [isPrepopulatingDone, setIsPrepopulatingDone] = useState(false);

  const { data: questionnaireSubmission } = useClioQuestionnaireSubmissionById(
    orgFprint,
    submissionId!,
    {
      enabled: !!submissionId,
    },
  );

  const {
    mutate: updateQuestionnaireSubmission,
    isLoading: isUpdatingQuestionnaireSubmission,
  } = useUpdateQuestionnaireSubmission(orgFprint);

  useEffect(() => {
    const getMatterData = async () => {
      if (!matterId || !orgFprint) return;

      try {
        setIsMatterError(false);
        const { matter } = await matterService.fetchMatter(orgFprint, matterId);

        setMatterData(matter as Instance<typeof Matter>);
      } catch (e) {
        setIsMatterError(true);
      }
    };

    getMatterData();
  }, [orgFprint, matterId, submissionId]);

  useEffect(() => {
    if (!submissionId || !matterData || !questionnaireSubmission) return;

    setIsPrepopulatingDone(false);
    const prepopulateResponses = createPrepopulateRecord(
      matterData.details?.v2Data,
      questionnaireSubmission,
    );
    updateQuestionnaireSubmission(
      {
        submissionId,
        submissionUpdate: {
          questionnaire_submission: {
            section_submissions_attributes: prepopulateResponses,
          },
        },
      },
      {
        onSettled: () => {
          setMatterData(null);
          setIsPrepopulatingDone(true);
        },
      },
    );
  }, [
    matterData,
    submissionId,
    questionnaireSubmission,
    updateQuestionnaireSubmission,
  ]);

  return {
    isError: isMatterError,
    setMatterId,
    submissionId,
    setSubmissionId,
    isPrepopulatingDone,
    isPrepopulatingLoading: isUpdatingQuestionnaireSubmission,
  };
};

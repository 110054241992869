import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Edit, Trash, Type } from 'react-feather';
import { MoreVert } from '@mui/icons-material';
import { CardListItemWithActions, CardListItemMetaText } from '../CardList';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as WordIcon } from '~/src/static/word.svg';
import { ReactComponent as PdfIcon } from '~/src/static/pdf.svg';
import { DOCUMENT_CATEGORY_ID } from '~/src/stores/sidebarItemsStore';
import { getFormattedDateFromIsoString } from '~/src/utils/date';

const getIcon = (documentType) =>
  documentType === DOCUMENT_CATEGORY_ID.MS_WORD ? WordIcon : PdfIcon;

export const CardListDocumentsTable = ({
  documents,
  handleEdit,
  handleView,
  handleRename,
  handleDownload,
  handleDelete,
}) => {
  return documents.map((document) => {
    const hasBeenGenerated = document.docx;

    const actions = [
      {
        label: 'Edit',
        icon: <Edit size={16} />,
        onClick: () => handleEdit(document),
      },
      {
        label: 'Rename',
        icon: <Type size={16} />,
        onClick: () => handleRename(document),
      },
    ];

    if (handleDelete) {
      actions.push({
        label: 'Delete',
        icon: <Trash size={16} />,
        onClick: () => handleDelete(document),
      });
    }

    return (
      <Box pb={1} key={document.id}>
        <CardListItemWithActions
          onClick={document.docx ? () => handleView(document) : null}
          icon={<SvgIcon component={getIcon(document.documentType)} />}
          key={document.id}
          title={{ label: document.title }}
          meta={
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <CardListItemMetaText>
                Last updated:{' '}
                {getFormattedDateFromIsoString(document.updatedAt)}
              </CardListItemMetaText>
              <IconButton sx={{ fontSize: 16 }}>
                <MoreVert fontSize={'inherit'} />
              </IconButton>
            </Box>
          }
          actions={{
            actions,
            primary: {
              label: 'Download',
              disabled: !hasBeenGenerated,
              onClick: () => handleDownload(document),
            },
          }}
        />
      </Box>
    );
  });
};

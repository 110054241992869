import React from 'react';
import { BundleProduct } from '~/src/entities/subscription/types';
import { planPrice } from './utils';
import { Chip, Typography } from '@mui/material';

const BundlePlanItem = ({ bundle }: { bundle: BundleProduct }) => {
  return (
    <div className="mb-3">
      <div className="flex flex-row justify-between text-xs">
        <div>
          <span>{bundle.productLabel}</span>
          <Chip
            label={
              <Typography
                variant={'caption'}
                sx={(theme) => ({
                  color: theme.palette.green[700],
                  fontWeight: 'bold',
                })}
              >
                {`Save $${bundle.priceDifferenceBundle / 100}`}
              </Typography>
            }
            sx={{
              backgroundColor: (theme) => theme.palette.green[50],
              padding: '0px',
              height: '80%',
            }}
          />
        </div>
        <div>
          <span className="text-gray-500 line-through mr-[5px]">
            {`$${(bundle.priceDifferenceBundle + bundle.price) / 100}`}
          </span>
          <span className="whitespace-nowrap">
            {planPrice(bundle.price / 100, bundle.billingCycle)}
          </span>
        </div>
      </div>
      {bundle.hint && (
        <div className="text-xs text-gray-500">{bundle.hint}</div>
      )}
    </div>
  );
};

export default BundlePlanItem;

/* Libraries */
import React from 'react';

/* Components */
import Table from '~/src/containers/Table';

import RowActions from '~/src/components/RowActions';
import AddButtonOrAddedIcon from '~/src/components/AddButtonOrAddedIcon/AddButtonOrAddedIcon';
import { TemplateDefaultValues } from '~/src/models';
import { Actions } from '../RowActions/RowActions';
import { getFormattedDateFromIsoString } from '~/src/utils/date';

interface Props {
  templateDefaultValues: TemplateDefaultValues[];
  empty: boolean;
  visibleItems: number;
  rowActions?: Actions<TemplateDefaultValues>;
  containedInSidebar: Function;
  isAddButtonDisabled: boolean;
  getAddButtonTooltip: string | null;
  addFormTemplateToSelectionSidebar: (t: TemplateDefaultValues) => void;
  onSearchChange: (s: string) => void;
  onSortByChange: (property: string, order: number) => void;
}

const TemplateDefaultValuesList = ({
  templateDefaultValues,
  empty,
  visibleItems,
  rowActions,
  containedInSidebar,
  isAddButtonDisabled,
  getAddButtonTooltip,
  addFormTemplateToSelectionSidebar,
  onSearchChange,
  onSortByChange,
}: Props) => {
  const renderRowAction = (templateDefaultValues: TemplateDefaultValues) => {
    return (
      <div className="flex">
        <div className="mr-6 flex items-center">
          <AddButtonOrAddedIcon
            templateDefaultValues={templateDefaultValues}
            containedInSidebar={containedInSidebar}
            handleAddTemplateToSelectionSidebar={
              addFormTemplateToSelectionSidebar
            }
            isAddButtonDisabled={isAddButtonDisabled}
            getAddButtonTooltip={getAddButtonTooltip}
          />
        </div>

        {rowActions && (
          <RowActions<TemplateDefaultValues>
            actions={rowActions}
            template={templateDefaultValues}
          />
        )}
      </div>
    );
  };

  return (
    <div data-analyticsname="Form Template Actions" className="flex">
      <Table
        emptyTitle={'No form templates match that filter'}
        empty={empty}
        searchPlaceholder="Search form templates"
        data={templateDefaultValues}
        visibleItems={visibleItems}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
        columns={[
          {
            label: 'Form template name',
            dataKey: 'title',
            style: {
              width: '38%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Last updated',
            dataKey: 'lastModified',
            render: getFormattedDateFromIsoString,
            style: { width: '10%', textAlign: 'left' },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />
    </div>
  );
};

export default TemplateDefaultValuesList;

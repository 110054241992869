import { Box, Card, Grid } from '@mui/material';
import Page from '~/src/components/PageLayout';
import React from 'react';

export const Subscribe = () => {
  return (
    <Page showNavigation={false} title={'Subscribe'}>
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'90%'} width={'900px'}>
          <Grid item xs={10} md={5}>
            <Card>
              <Box p={3}>
                <Box py={2}>
                  You have no active subscription. To enquire about your
                  subscription, please contact Clio support
                </Box>
              </Box>
            </Card>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

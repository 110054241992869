import React from 'react';
import { SummaryFormItem } from './SummaryFormItem';
import { FormattedInvoiceItem } from '~/src/entities/subscription/types';
import { planPrice } from './utils';

type SeatsItemProps = {
  seatsProduct: FormattedInvoiceItem | undefined;
  hint?: string;
};
export const SeatsItem = ({ seatsProduct, hint }: SeatsItemProps) => (
  <SummaryFormItem
    label={
      <>
        {seatsProduct?.quantity ?? 'Loading'} seat
        {seatsProduct?.quantity === 1 ? '' : 's'}
      </>
    }
    price={planPrice(
      (seatsProduct?.subtotal || 0) / 100,
      seatsProduct?.billingCycle || 'monthly',
    )}
    hint={hint}
  />
);

import React, { useState } from 'react';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

import ConfigurePlanModal from '../Modal/ConfigurePlan';
import { useGetProductPricing } from '~/src/entities/subscription/hooks/useGetProductPricing';
import { useGetQuestionnaireEmbeddedEligibility } from '~/src/entities/subscription/hooks/useGetQuestionnaireEmbeddedEligibility';

/* Higher Order Components */

/* Configurations */

import Label from './Label';
import Card from '../Card';
import Button from '../Button';
import { Error } from '~/src/components/Type';

const PlanChoiceInput = (props) => {
  const { label, hint, onChange, options, required } = props;

  const productPricing = useGetProductPricing();
  const questionnaireEmbeddedEligibility =
    useGetQuestionnaireEmbeddedEligibility();
  const [planModalIndex, setPlanModalIndex] = useState(null);

  const isPlanSelectModalOpen = planModalIndex !== null;

  if (productPricing.error) {
    return (
      <Error cssStyle={undefined}>{'Error loading Product Pricing'}</Error>
    );
  }
  if (questionnaireEmbeddedEligibility.error) {
    return (
      <Error cssStyle={undefined}>
        {'Error fetching Questionnaire eligibility'}
      </Error>
    );
  }

  const courtFormsSelected = options
    ?.filter((option) => option.label === 'Fillable court forms')?.[0]
    ?.defaultValue.some((entry) => entry); // defaultValue is a boolean array of CFs where true means it's selected

  const documentAutomationSelected =
    options?.filter(
      (option) => option.label === 'Word document automation',
    )?.[0]?.defaultValue?.[0] === true; // only one element in the array since there is only one DF plan

  const selectedProductCategories = {
    courtForms: courtFormsSelected,
    documentAutomation: documentAutomationSelected,
  };

  const content = options.map((field, index) => {
    const { label, plans, defaultValue, subHeading } = field;
    const labelHasProducts = index === 1 ? 'Remove' : 'Edit'; // 1 = DA, 0 = CF
    return (
      <div className="w-full mb-2" key={index}>
        <Card key={index} dynamic>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <div className="font-medium text-sm mr-4">{label}</div>
              {subHeading && (
                <div className="text-xs text-gray-500">{subHeading}</div>
              )}
            </div>

            {plans.length > 0 && (
              <Button onClick={() => setPlanModalIndex(index)} primary>
                {defaultValue.includes(true) ? labelHasProducts : 'Add'}
              </Button>
            )}
          </div>
        </Card>
      </div>
    );
  });

  const handleChange = (data) => {
    onChange(data);
    setPlanModalIndex(null);
  };

  return (
    <>
      <Label label={label} required={required} hint={hint} className="mb-5">
        {content}
      </Label>
      {options?.[planModalIndex] && (
        <ConfigurePlanModal
          open={isPlanSelectModalOpen}
          index={planModalIndex}
          handleCancel={() => setPlanModalIndex(null)}
          handleChange={handleChange}
          productPricingData={productPricing.data || {}}
          selectedProductCategories={selectedProductCategories}
          isEligibleForQuestionnairesEmbedded={
            !!questionnaireEmbeddedEligibility.data?.is_eligible_for_embedded
          }
          {...options?.[planModalIndex]}
        />
      )}
    </>
  );
};

export default WithLayoutProps(PlanChoiceInput);

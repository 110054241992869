import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Eye } from 'react-feather';

const StyledEyeIcon = styled(Eye)(`
  min-width: 13.3px;
  position: absolute;
  top: 4px;
`);

type ProgressTrackerWidgetProps = {
  widgetRef: React.RefObject<HTMLDivElement>;
  fieldName?: string;
  totalFieldsCount: number;
  fieldsReviewedCount: number;
  totalFilledFieldsCount: number;
  onNextClick: () => void;
  onReviewAgainClick?: () => void;
  onFieldNameClick: () => void;
  onGenerate: () => void;
  onClose: () => void;
  isGenerating: boolean;
};

const FieldNameTypography = styled(Typography)(`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 0 20px 0;
    text-decoration: underline;
  }
`);

const ProgressTrackerWidget = ({
  widgetRef,
  fieldName,
  onNextClick,
  onReviewAgainClick,
  totalFieldsCount,
  fieldsReviewedCount,
  totalFilledFieldsCount,
  onFieldNameClick,
  onGenerate,
  onClose,
  isGenerating,
}: ProgressTrackerWidgetProps) => {
  const areAllFieldsReviewed = fieldsReviewedCount === totalFieldsCount;
  const areAllFieldsFilled = totalFilledFieldsCount === totalFieldsCount;
  const noFieldIsSelected = fieldName === undefined;

  const getFieldNameTypography = () => {
    if (noFieldIsSelected) {
      let msg = 'No field is selected.';
      if (areAllFieldsFilled) {
        msg = 'No blank fields remaining.';
      } else if (areAllFieldsReviewed) {
        msg = 'All blank fields are reviewed';
      }
      return (
        <Typography
          variant="button"
          sx={(theme) => ({
            color: theme.palette.grey[400],
          })}
        >
          {msg}
        </Typography>
      );
    }
    return (
      <FieldNameTypography variant="button" onClick={onFieldNameClick}>
        <p>{fieldName}</p>

        <Tooltip title={'Go to field'} placement="right" arrow>
          <IconButton sx={{ width: 16, height: 16, paddingBottom: 1 }}>
            <StyledEyeIcon size={11} />
          </IconButton>
        </Tooltip>
      </FieldNameTypography>
    );
  };
  return (
    <Card
      ref={widgetRef}
      raised
      sx={{
        padding: '4px',
        width: '312px',
        position: 'fixed',
        left: 'calc(50% + 380px)',
        top: '20%',
        transition: 'top 500ms ease',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', marginBottom: '20px' }}>
          <Box sx={{ marginRight: 'auto' }}>
            <Typography variant="subheading" sx={{ marginBottom: '16px' }}>
              Review fields
            </Typography>
          </Box>
          <Box>
            <IconButton
              sx={{ width: 16, height: 16, paddingBottom: 1 }}
              aria-label="close"
              onClick={() => {
                onClose();
              }}
            >
              <Close sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>

        <hr></hr>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.text.subdued,
            marginTop: '20px',
            textAlign: 'end',
          })}
        >
          {totalFieldsCount - fieldsReviewedCount}{' '}
          {totalFieldsCount - fieldsReviewedCount > 1 ? 'fields' : 'field'} to
          review
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(fieldsReviewedCount / totalFieldsCount) * 100}
          sx={(theme) => ({
            margin: '4px 0px 16px',
            backgroundColor: theme.palette.green[50],
            height: '10px',
            borderRadius: '10px',
            '& > .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.green[400],
            },
          })}
        />

        <Box sx={{ margin: '24px 0 0 0' }}>{getFieldNameTypography()}</Box>

        {!areAllFieldsReviewed && (
          <Button
            onClick={onNextClick}
            className="mb-4"
            sx={(theme) => {
              return {
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.grey[900],
                border: 'none',
                padding: '6px 16px',
                '&:hover': {
                  backgroundColor: theme.palette.grey[200],
                  border: 'none',
                },
              };
            }}
          >
            Next
          </Button>
        )}

        {areAllFieldsReviewed && !areAllFieldsFilled && (
          <Button
            onClick={onReviewAgainClick}
            className="mb-4"
            sx={(theme) => ({
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.grey[900],
              border: 'none',
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
                border: 'none',
              },
            })}
          >
            Review again
          </Button>
        )}

        {areAllFieldsReviewed && areAllFieldsFilled && (
          <Button
            onClick={onGenerate}
            disabled={isGenerating}
            className="mb-4"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.page,
              color: theme.palette.grey[900],
              border: 'none',
              '&:hover': {
                border: 'none',
              },
            })}
          >
            Generate document(s)
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgressTrackerWidget;

/* Libraries */
import React from 'react';
import { useObserver } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { ChevronDown, ChevronUp, Eye } from 'react-feather';
import { getFormattedDateFromIsoString } from '~/src/utils/date';

/* Components */
import MiniSpinner from '~/src/components/Spinner/MiniSpinner';
import { Text } from '~/src/components/Typography';
import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';
import { ReactComponent as WordIcon } from '~/src/static/word.svg';

import dropdownStyles from '~/src/components/Dropdown/styles';

/* Hooks */
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';
import useMst, { useStore } from '~/src/hooks/useMst';

/* Styles */
import { ReactComponent as ItemSelectedIcon } from '~/src/static/item-selected.svg';
import styles from './styles';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';

const DraftDocSetButton = ({ numDocs, nextPath }) => (
  <Link to={nextPath}>
    <Button primary>{`Draft ${numDocs} documents`}</Button>
  </Link>
);

const TemplatesActions = ({ selectedTemplateIds }) => {
  const numTemplates = selectedTemplateIds.length;
  const queryParams = qs.stringify(
    { ids: selectedTemplateIds },
    { encode: false },
  );
  const path = `/templates/docset/new?${queryParams}`;

  return (
    <div>
      {numTemplates > 0 && (
        <DraftDocSetButton numDocs={numTemplates} nextPath={path} />
      )}
    </div>
  );
};

const RowAction = ({
  templateId,
  onPreview,
  onRename,
  onDelete,
  hideDraftButton,
  onPreviewTemplate,
}) => {
  if (onPreviewTemplate) {
    return (
      <span analyticsname="Preview Template">
        <Button onClick={onPreviewTemplate}>
          <span>Preview</span>
        </Button>
      </span>
    );
  }

  const renderTrigger = ({ toggleDropdown }) => {
    const handleClick = (event) => {
      event.stopPropagation();
      toggleDropdown();
    };
    return (
      <span analyticsname="Actions">
        <Button onClick={handleClick} cssStyle={styles.rowActionButton}>
          Actions <Triangle primary />
        </Button>
      </span>
    );
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      {!hideDraftButton && (
        <Link
          to={`/templates/${templateId}/new`}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Draft New Document"
        >
          Draft new document
        </Link>
      )}
      <a
        onClick={onPreview}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Preview Template"
      >
        Preview template
      </a>
      <a
        onClick={onRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      {onDelete && (
        <a
          onClick={onDelete}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Delete"
        >
          Delete
        </a>
      )}
    </Dropdown>
  );
};

const ChildNameColumn = ({ templateTitle, templateId }) => {
  const { templates: templatesStore } = useStore();
  const isLoading = useObserver(() => {
    return templatesStore.childrenTemplatesLoadingState.get(templateId);
  });
  if (isLoading) {
    return <MiniSpinner />;
  } else {
    return (
      <div className="flex items-center gap-3 pl-9">
        <div>
          <WordIcon />
        </div>
        <Text>{templateTitle}</Text>
      </div>
    );
  }
};

const ChildRowAction = ({ template, rowActions }) => {
  const { templates: templatesStore } = useStore();
  const isLoading = useObserver(() => {
    return templatesStore.childrenTemplatesLoadingState.get(template.id);
  });
  if (!isLoading) {
    return (
      <div className="flex">
        <Button
          Icon={Eye}
          cssStyle={styles.childRowActionButton}
          onClick={() => rowActions.preview(template)}
        />
      </div>
    );
  }
  return null;
};

const Templates = ({
  templates,
  visibleItems,
  empty,
  rowActions,
  onAddTemplateToSeletionSidebar,
  selectedTemplateIds,
  onSearchChange,
  onSortByChange,
  onRowClick,
  hideDraftButton,
  onPreviewTemplate,
}) => {
  const {
    sidebarItemTotalNumber,
    containedInSidebar,
    includedInOtherTemplates,
  } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      sidebarItemTotalNumber: store.sidebarItems.items.length,
      containedInSidebar: store.sidebarItems.containedInSidebar,
      includedInOtherTemplates: store.templates.includedInOtherTemplates,
    })),
  );

  const {
    isUnifiedPlatform,
    isAddinLongDocumentsEnabled,
    isWordDocumentAutomationEnabled,
  } = useFeatureAccesses();

  /*
  ProX, by default, is used for word template automation.
  Subscription "word template automation" does NOT exist in ProX.
  */
  const allowDraftWithWordDocument =
    !isUnifiedPlatform ||
    (isUnifiedPlatform && isWordDocumentAutomationEnabled);

  const renderAddButtonOrAddedIcon = (template) => {
    if (containedInSidebar(template)) {
      return (
        <div className="flex">
          <div className="mr-2 flex items-center">
            <ItemSelectedIcon />
          </div>
          <div className="leading-none">Added</div>
        </div>
      );
    }

    return (
      <div>
        <Button
          data-tip
          data-for={`add-button-${template.id}`}
          onClick={(event) => {
            event.stopPropagation();
            onAddTemplateToSeletionSidebar(template);
          }}
          disabled={
            !allowDraftWithWordDocument ||
            sidebarItemTotalNumber >= TOTAL_DOCUMENT_LIMIT
          }
        >
          <span>Add</span>

          <ReactTooltip
            id={`add-button-${template.id}`}
            place="bottom"
            effect="solid"
            getContent={() => {
              if (!allowDraftWithWordDocument) {
                return 'Word document automation subscription is required';
              }
              if (sidebarItemTotalNumber >= TOTAL_DOCUMENT_LIMIT) {
                return 'Cannot add more than 20 documents to selection.';
              }
              return null;
            }}
          ></ReactTooltip>
        </Button>
      </div>
    );
  };

  const renderRowAction = (template) => {
    return (
      <div className="flex">
        <div className="mr-6 flex items-center">
          {renderAddButtonOrAddedIcon(template)}
        </div>

        {allowDraftWithWordDocument && (
          <RowAction
            templateId={template.id}
            onPreview={() => rowActions.preview(template)}
            onRename={() => rowActions.rename(template)}
            onDelete={
              isAddinLongDocumentsEnabled &&
              includedInOtherTemplates(template.id)
                ? null
                : () => rowActions.delete(template)
            }
            hideDraftButton={hideDraftButton}
            onPreviewTemplate={
              onPreviewTemplate ? () => onPreviewTemplate(template) : undefined
            }
          />
        )}
      </div>
    );
  };

  const renderChildRowAction = (template) => {
    return <ChildRowAction template={template} rowActions={rowActions} />;
  };

  const renderNameColumn = (templateTitle, { rowData, showChildren }) => {
    const showChevron = rowData.isBaseTemplate && rowData.children.length > 0;
    const showIcon = !rowData.isBaseTemplate;
    return (
      <div className="flex items-center gap-3">
        {showChevron && (
          <div className="rounded-full w-6 h-6 flex justify-center items-center hover:bg-gray-100">
            {!showChildren && <ChevronDown size={16} />}
            {showChildren && <ChevronUp size={16} />}
          </div>
        )}
        {showIcon && (
          <div>
            <WordIcon />
          </div>
        )}
        <Text strong>{templateTitle}</Text>
      </div>
    );
  };

  const renderChildNameColumn = (templateTitle, { rowData }) => {
    return (
      <ChildNameColumn templateTitle={templateTitle} templateId={rowData.id} />
    );
  };

  const handleRowClick = (data) => {
    const templateIds = data.children.map((template) => template.id);
    onRowClick(templateIds);
  };

  return (
    <div analyticsname="Template Actions" className="flex">
      <Table
        emptyTitle={'No templates match that filter'}
        empty={empty}
        isLoading={false}
        searchPlaceholder="Search templates"
        renderActions={() => (
          <TemplatesActions selectedTemplateIds={selectedTemplateIds} />
        )}
        data={templates}
        rowClickable={isAddinLongDocumentsEnabled}
        onRowClick={handleRowClick}
        visibleItems={visibleItems}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
        columns={[
          {
            label: 'Template name',
            dataKey: 'title',
            style: {
              width: '38%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
            render: isAddinLongDocumentsEnabled ? renderNameColumn : null,
          },
          {
            label: 'Last updated',
            dataKey: 'updatedAt',
            render: getFormattedDateFromIsoString,
            style: { width: '10%', textAlign: 'left' },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: {
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        ]}
        childColumns={
          isAddinLongDocumentsEnabled
            ? [
                {
                  label: 'Name',
                  dataKey: 'title',
                  style: {
                    width: '20%',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                  render: renderChildNameColumn,
                },
                {
                  label: 'Actions',
                  hideLabel: true,
                  render: renderChildRowAction,
                  style: {
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
              ]
            : null
        }
      />
    </div>
  );
};

Templates.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
  rowActions: PropTypes.shape({
    draft: PropTypes.func,
    preview: PropTypes.func,
    rename: PropTypes.func,
    delete: PropTypes.func,
  }),

  onSearchChange: PropTypes.func,
  onSortByChange: PropTypes.func,
  hideDraftButton: PropTypes.bool,
  onPreviewTemplate: PropTypes.func,
};

Templates.defaultProps = {
  templates: [],
};

export default Templates;

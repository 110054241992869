import React from 'react';
import { Alert, Typography } from '@mui/material';
import { AlertCircle } from 'react-feather';
import { getFormattedFullDateFromIsoString } from '~/src/utils/date';

type PastDueBannerProps = {
  endDate: string;
  extra?: React.ReactNode;
};

export const PastDueBanner = ({ endDate, extra }: PastDueBannerProps) => {
  return (
    <Alert
      data-testid="subscription-canceled-banner"
      icon={<AlertCircle color="black" />}
      severity="warning"
      sx={{ background: '#fff1c8' }}
      variant="standard"
    >
      <Typography variant={'body1'}>
        Your subscription was past due on{' '}
        {getFormattedFullDateFromIsoString(endDate)}
      </Typography>
      {extra}
    </Alert>
  );
};

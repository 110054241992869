import { Contact } from '../contact';

export enum DocumentType {
  TAGGED_PDF = 1,
  PDF = 2,
  TEXT = 3,
  MS_WORD = 4,
}

export type PreviewDocumentPage = {
  background_url: string;
  is_processed: boolean;
  page: number;
  pageid: number;
};

export type ProjectStackDocument = {
  docid: number;
  title: string;
  subtitle: string;
  template_docid: number;
  global_template_docid: number;
  document_type: DocumentType;
  original_file: string;
  document_fields: Record<string, { source_tag: string; alias_tag: string }>;
};

export type PreviewDocument = {
  docid: number;
  title: string;
  subtitle: string;
  template_docid: number;
  global_template_docid?: number;
  document_type: DocumentType;
  original_file: string;
  document_fields: Record<string, { source_tag: string; alias_tag: string }>;
  pages: PreviewDocumentPage[];
};

export type PreviewDocumentPdfResponse = {
  document: PreviewDocument;
};

export type PreviewDocumentWordResponse = {
  docid: number;
  documen_type: DocumentType;
  document_fields: Record<string, { source_tag: string; alias_tag: string }>;
  preview_pages: PreviewDocumentPage[];
};

export const SUPPORTED_DATE_FORMATS = [
  'MM/DD/YY',
  'MM/DD/YYYY',
  'MM.DD.YY',
  'MM.DD.YYYY',
  'DD/MM/YY',
  'DD/MM/YYYY',
  'DD.MM.YY',
  'DD.MM.YYYY',
  'MMM. D',
  'MMM-D',
  'MMMM D',
  'D MMMM YYYY',
  'MMMM D, YYYY',
  'Do [day of] MMMM, YYYY',
  'dddd, MMMM D, YYYY',
  'MMM. D, YY',
  'YYYY-MM-DD',
  'MM-DD-YYYY',
  'DD-MM-YYYY',
  'YYYY.MM.DD',
];

export enum FieldType {
  SHORT_TEXT = 1,
  LONG_TEXT = 2,
  MULTIPLE_CHOICE = 3,
  DATE = 4,
  BATCH_SELECT = 5,
}

export type StringFieldType =
  | 'SHORT_TEXT'
  | 'LONG_TEXT'
  | 'MULTIPLE_CHOICE'
  | 'DATE'
  | 'BATCH_SELECT';

export type MultipleChoiceOption = {
  option: string;
  value: string;
  formatted?: boolean;
};

export type ProjectStackField = {
  fid: string;
  label: string;
  sublabel?: string;
  subsublabel?: string;
  is_standalone_question?: boolean;
  hint?: string;
  value?: string;
  num_instances: number;
  variable_type: FieldType | StringFieldType;
  multiple_choice_variable?: { options: MultipleChoiceOption[] };
  date_format?: string;
  single_select_display_type?: 'Dropdown' | 'List' | '';
  batch_select_print_as?: string;
  sort_order?: number;
};

export type ProjectStackFieldGroup = {
  label: string;
  gid: string;
  sort_order: number;
  fields: string[];
  entity_type?: 'Contact' | 'Other';
  related_contact_id?: number;
  org_contact?: Contact;
};

export type ProjectStackMetaData = {
  id: number;
  matter_id: number;
  matter_title: string;
};

export type ProjectStackData = {
  project_metadata: ProjectStackMetaData;
  documents: ProjectStackDocument[];
  fields: ProjectStackField[];
  groups: ProjectStackFieldGroup[];

  data_stack?: {
    data: Record<string, any>;
    metadata: Record<string, any | Record<string, any>>;
  };
};

export type ProjectStackSavedData = Record<string, string>;

export type Project = {
  id: number;
  title: string;
  created_at: string;
  last_modified: string;
  fprint: string;
  document_fields: Record<string, any>;
  stack_data: ProjectStackData;
  stack_saved_data: ProjectStackSavedData;
};

export type ProjectContact = {
  project_id: number;
  matter_id: number;
  linked_on: string;
  linked_by?: string;
  role: string;
  org_contact: Contact;
};

export type ProjectPopulateResponse = {
  task_id?: string;
};
export enum AsyncResultStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  FAILURE = 'FAILURE',
}

type ResultType = { [key: string]: any };

export type AsyncResultResponse = {
  task_id: string;
  status:
    | AsyncResultStatus.SUCCESS
    | AsyncResultStatus.PENDING
    | AsyncResultStatus.STARTED
    | AsyncResultStatus.FAILURE;
  result?: ResultType;
  traceback?: string;
};

export type ProjectGenerationTaskStatusResponse = AsyncResultResponse & {
  result?: ResultType & {
    project_id: number;
  };
};

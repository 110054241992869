import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';
import { CardListDocumentsTable } from './CardListDocumentsTable';
import dropdownStyles from '~/src/components/Dropdown/styles';
import styles from './styles';
import useDocumentsActions from '~/src/hooks/useDocumentsActions';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { SearchField } from '../SearchField';
import { Box, Typography } from '@mui/material';
import { useCurrentOrg } from '~/src/entities/user';
import { getFormattedDateFromIsoString } from '~/src/utils/date';

const DocumentActions = () => {
  return <div />;
};

const RowAction = ({ actions, document }) => {
  const renderTrigger = ({ toggleDropdown }) => (
    <span analyticsname="Actions">
      <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
        Actions <Triangle primary orientation={'down'} />
      </Button>
    </span>
  );

  const hasDocumentBeenGenerated = !!document.docx;

  const handleDelete = () => {
    actions.delete(document);
  };

  const handleEdit = () => {
    actions.edit(document);
  };

  const handleView = () => {
    actions.view(document);
  };

  const handleRename = () => {
    actions.rename(document);
  };

  const handleDownload = () => {
    actions.download(document);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      {hasDocumentBeenGenerated && (
        <a
          onClick={handleView}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="View"
        >
          View
        </a>
      )}
      <a
        onClick={handleEdit}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Edit"
      >
        Edit
      </a>
      {hasDocumentBeenGenerated && (
        <a
          onClick={handleDownload}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Download"
        >
          Download
        </a>
      )}
      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      {actions.delete && (
        <a
          onClick={handleDelete}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Delete"
        >
          Delete
        </a>
      )}
    </Dropdown>
  );
};

const Documents = ({
  hideHeader,
  empty,
  columnWidths,
  noBorder,
  visibleItems,
  documents,
  onSortByChange,
  onSearchChange,
}) => {
  const { org } = useCurrentOrg();
  const isAdmin = org.admin;
  const isInternalUser = org.enable_dev_tools;

  const {
    deleteMatterDocument: handleDeleteDocument,
    editDocument: handleEditDocument,
    viewDocument: handleViewDocument,
    renameMatterDocument: handleRenameDocument,
    downloadDocument: handleDownloadDocument,
  } = useDocumentsActions();
  const rowActions = {
    ...((isAdmin || isInternalUser) && { delete: handleDeleteDocument }),
    edit: handleEditDocument,
    view: handleViewDocument,
    rename: handleRenameDocument,
    download: handleDownloadDocument,
  };

  const renderRowAction = (document) => (
    <RowAction actions={rowActions} document={document} />
  );

  const useCardLists = useUIFlagEnabled('cardLists');

  if (useCardLists) {
    return (
      <Box mx={3}>
        <Box maxWidth={1280} width={'100%'} mx={'auto'}>
          <Box pb={3}>
            <SearchField
              onChange={onSearchChange}
              size="small"
              placeholder="Search Documents"
            />
          </Box>
          {empty ? (
            <Typography textAlign={'center'} variant="subtitle1">
              No documents match that filter
            </Typography>
          ) : (
            <CardListDocumentsTable
              handleDelete={rowActions.delete}
              handleEdit={rowActions.edit}
              handleView={rowActions.view}
              handleRename={rowActions.rename}
              handleDownload={rowActions.download}
              documents={documents}
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div analyticsname="Document Actions">
        <Table
          noBorder={noBorder}
          hideHeader={hideHeader}
          emptyTitle={'No documents match that filter'}
          empty={empty}
          isLoading={false}
          searchPlaceholder="Search documents"
          renderActions={() => <DocumentActions />}
          onSearchChange={onSearchChange}
          onSortByChange={onSortByChange}
          data={documents}
          visibleItems={visibleItems}
          columns={[
            {
              label: 'Document name',
              dataKey: 'title',
              style: {
                width: columnWidths[0],
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
            {
              label: 'Last updated',
              dataKey: 'updatedAt',
              render: getFormattedDateFromIsoString,
              style: { width: columnWidths[1], textAlign: 'left' },
            },
            {
              label: 'Actions',
              hideLabel: true,
              render: renderRowAction,
              style: {
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
          ]}
        />
      </div>
    </>
  );
};

Documents.propTypes = {
  columnWidths: PropTypes.arrayOf(PropTypes.string),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
  visibleItems: PropTypes.number,
  rowActions: PropTypes.shape({
    delete: PropTypes.func,
  }),
};

Documents.defaultProps = {
  documents: [],
  columnWidths: ['60%', '10%'],
  visibleItems: 10,
  rowActions: {
    delete: () => {},
  },
};

export default Documents;

import React, { useState, useEffect } from 'react';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';
import Card from '~/src/components/Card';
import ConfigureCbsContentPackagesModal from '~/src/components/Modal/ConfigureCbsContentPackages';
import { COURT_FORMS } from '~/src/entities/subscription/constants';
import { useCurrentOrgFprint } from '~/src/entities/user';
import {
  updateContentPackages,
  updateContentPackageWizardStatus,
} from '~/src/entities/subscription/utils';
import Button from '~/src/components/Button';
import { Error } from '~/src/components/Type';
import { useHistory } from 'react-router-dom';
import { useSubscription } from '~/src/entities/subscription';

export const ContentPackageWizard = () => {
  const { data: subscriptionData, refetch: refetchSubscription } =
    useSubscription();
  const orgFprint = useCurrentOrgFprint();
  const [modalOpen, setModalOpen] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const history = useHistory();
  const contentPackagesPurchased =
    subscriptionData?.content_packages_purchased || 0;

  useEffect(() => {
    try {
      if (COURT_FORMS && Array.isArray(COURT_FORMS)) {
        setPlanData(COURT_FORMS);
        setSelectedPackages(Array(COURT_FORMS.length).fill(false));
      } else {
        setLoadError('Court form data is not available');
      }
    } catch (error) {
      setLoadError('Error loading court forms data');
      console.error('Error initializing court forms:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const [selectedPackages, setSelectedPackages] = useState([]);

  const handleOpenModal = () => {
    setModalOpen(true);
    setValidationError(null);
  };

  const handleCancelModal = () => {
    setModalOpen(false);
  };

  const validateSelection = (selected) =>
    Array.isArray(selected) &&
    selected.filter(Boolean).length === contentPackagesPurchased;

  const handleChangePackages = (selected) => {
    if (!validateSelection(selected)) {
      setValidationError(
        `You must select exactly ${contentPackagesPurchased} content package${
          contentPackagesPurchased !== 1 ? 's' : ''
        }.`,
      );
      setSelectedPackages(selected);
      setModalOpen(false);
      return;
    } else if (subscriptionData?.content_packages_wizard_ran) {
      setValidationError(
        `You have already selected your content packages. You can only select your content packages once.`,
      );
      setSelectedPackages(selected);
      setModalOpen(false);
      return;
    }

    setSelectedPackages(selected);
    setModalOpen(false);
    setSaveStatus(null);
    setValidationError(null);
  };

  const handleUpdatePackages = (selected) => {
    return selected;
  };

  const handleSavePackages = async () => {
    if (!orgFprint) {
      setSaveStatus('error');
      return;
    }

    if (!validateSelection(selectedPackages)) {
      setValidationError(
        `You must select exactly ${contentPackagesPurchased} content package${
          contentPackagesPurchased !== 1 ? 's' : ''
        }.`,
      );
      return;
    }

    setIsSaving(true);
    setSaveStatus(null);
    setValidationError(null);

    try {
      const planIds = planData.map((plan) => plan.id);
      await updateContentPackages(selectedPackages, planIds, orgFprint);
      await updateContentPackageWizardStatus(orgFprint, 'true');

      if (refetchSubscription && typeof refetchSubscription === 'function') {
        await refetchSubscription();
      }

      setSaveStatus('success');
      history.push('/library');
    } catch (error) {
      console.error('Error saving content packages:', error);
      setSaveStatus('error');
    } finally {
      setIsSaving(false);
    }
  };

  if (loadError) {
    return <Error cssStyle={undefined}>{loadError}</Error>;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading content packages
      </div>
    );
  }

  const hasSelectedPackages = selectedPackages.some((selected) => selected);
  const selectedCount = selectedPackages.filter((item) => item).length;
  const isSelectionValid =
    selectedCount === contentPackagesPurchased &&
    !subscriptionData?.content_packages_wizard_ran;

  return (
    <div className="flex flex-col items-center justify-start min-h-screen pt-20">
      <div className="max-w-lg w-full mx-auto p-6 my-8">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Configure Content Packages
        </h1>

        <Card dynamic>
          <div className="flex flex-row items-center justify-between p-4">
            <div className="flex flex-col">
              <div className="font-medium text-sm mr-4">Content Packages</div>
              <div className="text-xs text-gray-500">
                Select which court form libraries you want to use. Your
                subscription includes{' '}
                <strong>{contentPackagesPurchased}</strong> content package
                {contentPackagesPurchased !== 1 ? 's' : ''}.
              </div>
            </div>

            <Button onClick={handleOpenModal} primary>
              {hasSelectedPackages ? 'Edit' : 'Add'}
            </Button>
          </div>
        </Card>

        {hasSelectedPackages && (
          <Card dynamic className="mt-4">
            <div className="p-4">
              <h2 className="font-medium text-sm mb-2">
                Selected Content Packages:{' '}
                <span className="text-sm font-normal">
                  ({selectedCount}/{contentPackagesPurchased})
                </span>
              </h2>
              <ul className="list-disc pl-5 mb-4">
                {selectedPackages.map(
                  (selected, index) =>
                    selected &&
                    planData[index] && (
                      <li key={index} className="text-sm">
                        {planData[index].name}
                      </li>
                    ),
                )}
              </ul>

              <div className="flex justify-end pt-3 border-t border-gray-200">
                <Button
                  primary
                  onClick={handleSavePackages}
                  disabled={isSaving || !isSelectionValid}
                  loading={isSaving ? 'true' : undefined}
                >
                  {isSaving ? 'Saving...' : 'Save Selections'}
                </Button>
              </div>
            </div>
          </Card>
        )}

        {validationError && (
          <div className="mt-3 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded text-center">
            {' '}
            {validationError}
          </div>
        )}

        {saveStatus === 'error' && (
          <div className="mt-3 font-medium text-sm text-red-600">
            Submission failed, please try again.
          </div>
        )}

        {modalOpen && planData.length > 0 && (
          <ConfigureCbsContentPackagesModal
            open={modalOpen}
            plans={planData}
            defaultValue={selectedPackages}
            handleCancel={handleCancelModal}
            handleChange={handleChangePackages}
            handleUpdate={handleUpdatePackages}
            orgFprint={orgFprint}
            requiredSelections={contentPackagesPurchased}
          />
        )}
      </div>
    </div>
  );
};

export default WithLayoutProps(ContentPackageWizard);

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';
import env from './env';

const bugsnag = {
  instance: null as typeof Bugsnag | null,
  ErrorBoundary: null as React.ComponentType<any> | null,

  init() {
    // Initialize Bugsnag
    Bugsnag.start({
      apiKey: env.bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
    });

    // Initialize Performance monitoring
    BugsnagPerformance.start({
      apiKey: env.bugsnagApiKey,
    });

    // Store instance and ErrorBoundary
    this.instance = Bugsnag;
    this.ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

    return this;
  },
};

bugsnag.init();

export default bugsnag;

import React, { useEffect } from 'react';
import { LoadingOverlay } from '~/src/components/PageLayout';
import {
  Document,
  FieldGroup,
  Question,
  Section,
  mergeFieldGroups,
} from '@clio/questionnaire-builder';
import {
  useGetClioQuestionnaireById,
  useGetDocumentFields,
  useGetQuestionnaireTemplates,
  useUpdateClioQuestionnaire,
} from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { useSnackbar } from 'notistack';
import { history } from '~/src/utils/history';
import { untaggedFields } from './utils';

type QuestionOption = {
  label: string;
  value?: string;
  question_option_type: 'QuestionOptionStatic' | 'QuestionOptionCustom';
  question_option_mappings_attributes: {
    external_field_identifier: string;
  }[];
};

type PartialQuestion = Partial<Question> & {
  question_options_attributes?: Partial<QuestionOption>[];
  question_mappings_attributes: Partial<{
    external_field_identifier: string;
    questionnaire_id: number;
  }>[];
};

export const QuestionnaireAutoGeneration = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) => {
  const orgFprint = useCurrentOrgFprint();
  const snackbar = useSnackbar();
  const uiFlags = {
    useReuseUntaggedFieldMappings: useUIFlagEnabled(
      'questionnaireReuseUntaggedFieldMappings',
    ),
    autoGeneratePredefinedQuestionnaireQuestions: useUIFlagEnabled(
      'autoGeneratePredefinedQuestionnaireQuestions',
    ),
  };

  const [isSuccess, setIsSuccess] = React.useState(false);
  const [hasAutoGenerateErrors, setHasAutoGenerateErrors] =
    React.useState(false);
  const [isTemplatesReady, setIsTemplatesReady] = React.useState(false);
  const [isDocsReady, setIsDocsReady] = React.useState(false);
  const [isQuestionnaireReady, setIsQuestionnaireReady] = React.useState(false);

  const { data: questionnaireTemplates, isError: isTemplatesError } =
    useGetQuestionnaireTemplates(orgFprint, questionnaireId, {
      onSuccess: () => {
        setIsTemplatesReady(true);
      },
    });
  const {
    data: documents,
    isError: isDocumentMappingError,
    mutateAsync: getDocumentFields,
  } = useGetDocumentFields(orgFprint);

  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);

  const { data: questionnaire, isError: isQuestionnaireError } =
    useGetClioQuestionnaireById(orgFprint, questionnaireId, {
      onSuccess: () => {
        setIsQuestionnaireReady(true);
      },
    });

  const createPredefinedQuestions = (
    fieldGroup: FieldGroup,
  ): PartialQuestion[] => {
    return fieldGroup.predefined_questions.map((predefinedQuestion) => ({
      title: predefinedQuestion.question,
      description: predefinedQuestion.hint ?? '',
      question_type: predefinedQuestion.question_type,
      question_options_attributes: predefinedQuestion.options.map((option) => ({
        label: option.option,
        value: option.option,
        question_option_type: 'QuestionOptionStatic',
        question_option_mappings_attributes: [],
      })),
      question_mappings_attributes: [],
      is_required: false,
    }));
  };

  const createQuestionsFromFieldGroup = (
    fieldGroup: FieldGroup,
    questionnaireId: string,
  ): PartialQuestion[] => {
    return Object.values(fieldGroup.fields).map((field) => {
      const combinedNormalizedField = `${fieldGroup.normalized_field_group}.${field.normalized_field}`;

      const question: PartialQuestion = {
        title: field.name,
        description: field.hint ?? '',
        question_type: field.type,
        question_options_attributes: [],
        question_mappings_attributes: [
          {
            questionnaire_id: parseInt(questionnaireId),
            external_field_identifier: combinedNormalizedField,
          },
        ],
        is_required: false,
      };

      if (
        question.question_type === 'QuestionMultipleChoiceSingle' ||
        question.question_type === 'QuestionMultipleChoiceMulti'
      ) {
        const questionOptions: QuestionOption[] =
          field.multiple_choice_options.map((option) => ({
            label: option.label,
            value: option.value,
            question_option_type: 'QuestionOptionStatic',
            question_option_mappings_attributes: [
              {
                external_field_identifier: String(option.value),
              },
            ],
          }));

        question.question_options_attributes = questionOptions;
      }

      return question;
    });
  };

  const createSectionsFromFieldGroups = (fieldGroups: {
    [key: string]: FieldGroup;
  }): Section[] => {
    return Object.values(fieldGroups).map((fieldGroup, index) => {
      const section: Section = {
        title: fieldGroup.name,
        description: '',
        questions_attributes: [],
        display_order: index,
        show_logic: null,
        logic_enabled: false,
      };

      return section;
    });
  };

  const createInitialQuestionnaire = async (
    autoSections: Section[],
    targetCount: number,
  ) => {
    return await updateClioQuestionnaire.mutateAsync({
      questionnaireId,
      clioQuestionnaire: {
        id: parseInt(questionnaireId),
        sections_attributes: autoSections,
        target_count: targetCount,
        analytics: [
          {
            name: 'Completed Questionnaire Auto Generation',
            data: {
              // This document information is sent with the auto generation analytic so it can be passed to the Selected Documents analytic that is fired at the same time
              document_ids: documents?.map((doc) => doc.id),
              document_types: documents?.map((doc) =>
                doc.preview_pdf_url ? 'pdf' : 'docx',
              ),
            },
          },
        ],
      },
    });
  };

  const mapSectionsToQuestions = async (
    sections: Section[],
    fieldGroups: Record<string, FieldGroup>,
  ) => {
    return await Promise.all(
      sections.map((section) => {
        const fieldGroup = Object.values(fieldGroups).find(
          (g) => g.name === section.title,
        );
        if (!fieldGroup) {
          setHasAutoGenerateErrors(true);
          return null;
        }

        const questions = [
          ...createQuestionsFromFieldGroup(fieldGroup, questionnaireId),
          ...(uiFlags.autoGeneratePredefinedQuestionnaireQuestions
            ? createPredefinedQuestions(fieldGroup)
            : []),
        ];

        if (!section.id || questions.length === 0) {
          setHasAutoGenerateErrors(true);
          return null;
        }

        return {
          id: section.id,
          questions_attributes: questions,
        };
      }),
    ).then(
      (mappedSections) =>
        mappedSections.filter(Boolean) as {
          id: number;
          questions_attributes: PartialQuestion[];
        }[],
    );
  };

  const updateFinalQuestionnaire = async (
    mappedSections: { id: number; questions_attributes: PartialQuestion[] }[],
    untaggedFieldsResult: any,
  ) => {
    await updateClioQuestionnaire.mutateAsync({
      questionnaireId,
      clioQuestionnaire: {
        id: parseInt(questionnaireId),
        // @ts-ignore: we can partially update the questionnaire.
        sections_attributes: mappedSections,
        auto_remapping: uiFlags.useReuseUntaggedFieldMappings
          ? untaggedFieldsResult
          : [],
        analytics: [{ name: 'Questionnaire Initial Data Fill', data: {} }],
      },
    });
  };

  useEffect(() => {
    const createQuestionnaire = async (documents: Document[]) => {
      const fieldGroups = mergeFieldGroups(documents);
      const autoSections = createSectionsFromFieldGroups(fieldGroups);

      const initialQuestionnaire = await createInitialQuestionnaire(
        autoSections,
        documents.length,
      );

      const mappedSections = await mapSectionsToQuestions(
        initialQuestionnaire.sections_attributes,
        fieldGroups,
      );

      const untaggedFieldsResult = untaggedFields(documents);

      await updateFinalQuestionnaire(mappedSections, untaggedFieldsResult);

      setIsSuccess(true);
    };

    if (!isQuestionnaireReady || !isDocsReady) return;

    if (
      documents &&
      documents.length > 0 &&
      questionnaire &&
      questionnaire.sections_attributes.length === 0
    ) {
      createQuestionnaire(documents);
    }

    if (
      (documents && documents.length === 0) ||
      (questionnaire && questionnaire.sections_attributes.length > 0)
    ) {
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuestionnaireReady, isDocsReady]);

  useEffect(() => {
    if (!isTemplatesReady || !questionnaireTemplates) return;

    getDocumentFields(
      {
        templateIds:
          questionnaireTemplates.map((template) =>
            String(template.template_id),
          ) ?? [],
      },
      {
        onSuccess: () => {
          setIsDocsReady(true);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplatesReady]);

  if (isDocumentMappingError || isTemplatesError || isQuestionnaireError) {
    snackbar.enqueueSnackbar(
      'Error, Failed to load Questionnaire Builder. Please try again later.',
      {
        variant: 'error',
      },
    );
    history.push(`/questionnaires/`);

    return <></>;
  }

  if (isSuccess) {
    // wait 1 second to allow the questionnaire to be saved before redirecting, if we don't wait, mapping doesn't appear in the builder.
    setTimeout(() => {
      history.push(
        `/questionnaires/${questionnaireId}/builder?isAutoGenerated=true&hasAutoGenerateErrors=${hasAutoGenerateErrors}`,
      );
    }, 1000);
  }

  return (
    <LoadingOverlay
      title="Generating questions from your documents..."
      relative={false}
    />
  );
};

import { getFormattedDateFromIsoString } from '~/src/utils/date';

/* Utilities */
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { keysToCamel } from '~/src/utils/objects';

import { sortBy, uniqBy } from 'lodash';

export const getTemplateIdentifier = (id) => `template:${id}`;
export const getLocalityIdentifier = (id) => `locality:${id}`;
export const getTemplateSetIdentifier = (id) => `templateSet:${id}`;
export const getMembershipIdentifier = (id) => `membership:${id}`;
export const getMatterIdentifier = (id) => `matter:${id}`;
export const getSignaturePackageIdentifier = (id) => `signaturePackage:${id}`;
export const getDocumentIdentifier = (id) => `document:${id}`;
export const getContactIdentifier = (id) => `contact:${id}`;
export const getQuestionnaireIdentifier = (id) => `questionnaire:${id}`;
export const getQuestionnaireResponseIdentifier = (id) =>
  `questionnaireResponse:${id}`;
export const getQuestionnaireQuestionGroupIdentifier = (id) =>
  `questionnaireQuestionGroup:${id}`;
export const getV2TagIdentifier = (id) => `tagIds:${id}`;
export const getTemplateDefaultValuesIdentifier = (id) =>
  `templateDefaultValues:${id}`;

const convertUpdatedAt = (dateString) => {
  return dateString;
};

/*
key: original text in API response
value: display text
*/
export const signature_status_map = {
  Closed: 'Completed',
  Declined: 'Declined',
  'Out for signature': 'Pending',
  'Waiting for sender': 'Draft',
  Drafting: 'Draft',
  Expired: 'Expired',
  Unknown: 'Unknown',
  Signed: 'Completed',
  Pending: 'Pending',
  Canceled: 'Canceled',
  Cancelled: 'Canceled',
};

/**
 *
 * API Data Transformers
 */

export const mapRecipient = (recipient) => ({
  id: recipient.id,
  name: recipient.name,
  email: recipient.email,
  font: recipient.font,
  submitted: recipient.submitted,
  signOrder: recipient.sign_order,
  text: recipient.text,
  status: recipient.status,
  image: recipient.image,
  xfdfSignature: recipient.xfdf_signature,
});

export const mapRecipients = (recipients) => {
  return recipients.map((recipient) => mapRecipient(recipient));
};

export const mapSignaturePackageElement = (element) => {
  const elementData = {
    id: element.id,
    signaturePageId: element.signature_page_id || element.pageid,
    top: element.top,
    left: element.left,
    width: element.width,
    imageWidth: element.image_width,
    imageHeight: element.image_height,
    lineHeight: element.line_height,
    tag: element.tag,
    value: element.value,
    height: element.height,
    inputType: element.input_type,
  };
  if (element.recipient) {
    elementData.recipient = mapRecipient(element.recipient);
  }
  return elementData;
};

/* Projects */
export const mapProjectDocumentPage = (page) => {
  return {
    backgroundUrl: page.background_url,
    id: page.pageid,
    page: page.page,
    isProcessed: page.is_processsed,
    elements:
      'elements' in page
        ? page.elements.map((element) => mapSignaturePackageElement(element))
        : [],
  };
};

export const getTemplatesWithValuesIdentifiers = (templateWithValues) => {
  return {
    template: getTemplateIdentifier(templateWithValues.template_id),
    templateDefaultValues: templateWithValues.template_default_values_id
      ? getTemplateDefaultValuesIdentifier(
          templateWithValues.template_default_values_id,
        )
      : null,
  };
};

export const mapAllElements = (element) => {
  return {
    top: element.top,
    left: element.left,
    height: element.height,
    width: element.width,
    id: element.id,
    lineHeight: element.line_height,
    pageNumber: element.page_number,
    inputType: element.input_type,
    computedFieldType: element.computed_field_type,
    computedTagDestination: element.computed_tag_destination,
    computedFieldTag: element.computed_field_tag,
    tag: element.tag,
    aliasTag: element.alias_tag,
  };
};
export const mapProjectDocuments = (documents) => {
  return documents.map((document) => {
    return {
      id: document.docid,
      identifier: getDocumentIdentifier(document.docid),
      document_fields: document.document_fields,
      pdf:
        document.ms_word_document_pdf ||
        document.ms_word_document ||
        document.original_file,
      allElements: document.all_elements
        ? document.all_elements.map(mapAllElements)
        : [],
      documentType: document.document_type,
      docx: document.ms_word_document,
      ms_pdf: document.ms_word_document_pdf || document.ms_word_document,
      processedMsPdf: document.ms_word_document_processed_pdf,
      processedDocx: document.ms_word_document_processed,
      title: document.title,
      subtitle: document.subtitle,
      templateId: document.template_docid,
      globalTemplateId: document.global_template_docid,
      defaultValueId: document.default_value_id,
      pages: document.pages ? document.pages.map(mapProjectDocumentPage) : [],
      processedPages: document.processed_pages
        ? document.processed_pages.map(mapProjectDocumentPage)
        : [],
    };
  });
};

export const mapGroupFields = (groups, fields, documents = []) => {
  const cards = [];
  const fieldMap = {};

  fields.forEach((field) => (fieldMap[field.fid] = field));

  const filterFields = (fieldId) => {
    const field = fieldMap[fieldId];
    // filter out multi select options from populate
    if (field.variable_type === 'MULTI_SELECT_OPTION') {
      return false;
    }
    return true;
  };

  const mapField = (fieldId) => {
    const field = fieldMap[fieldId];

    const isLongTextType =
      field.variable_type === 2 || field.variable_type === 'LONG_TEXT';
    const isMultipleChoiceType =
      field.variable_type === 3 || field.variable_type === 'MULTIPLE_CHOICE';
    const isDateType =
      field.variable_type === 4 || field.variable_type === 'DATE';
    const isMultipleChoiceCheckboxType =
      field.variable_type === 5 ||
      field.variable_type === 'MULTIPLE_CHOICE_CHECKBOX';
    const isMultiSelect = field.variable_type === 'MULTI_SELECT';

    const fieldMultipleChoice = field.multiple_choice_variable;
    const isMultipleChoice =
      fieldMultipleChoice.options &&
      (fieldMultipleChoice || isMultipleChoiceType); // takes care of multi select as well

    const multipleChoice = isMultipleChoice
      ? fieldMultipleChoice.options.map(({ option, value, xml }) => {
          const optionValue = xml ? option : value;

          return {
            label: option,
            // If this is a formatted multiple choice option then use the label as the value.
            // This is a weird legacy quirk that is need to properly render formatted multiple choice options
            value: optionValue || '',
          };
        })
      : [];

    let fieldType = 'text';
    if (isMultiSelect) {
      fieldType = 'populateMultiSelect';
    } else if (isMultipleChoiceCheckboxType) {
      // multiple selections in multiple choices
      fieldType = 'multipleChoiceCheckbox';
    } else if (isMultipleChoice) {
      // single selection in multiple choices
      fieldType = 'populateMultipleChoice'; // radio button list (by default)
      if (field.single_select_display_type === 'Dropdown') {
        fieldType = 'singleSelectDropdown';
      }
    } else if (isDateType) {
      fieldType = 'date';
    } else if (isLongTextType) {
      fieldType = 'textarea';
    }

    const fieldDocuments = documents.filter((d) => {
      if (fieldId in d.document_fields) {
        return true;
      }
      return false;
    });

    const mappedField = {
      id: fieldId,
      alias: field.fid,
      tag: field.tag,
      label: field.label,
      documents: fieldDocuments,
      hint: field.hint,
      options: multipleChoice,
      validation: VALIDATION_TYPES.present,
      value: field.value,
      type: fieldType,
      count: field.num_instances,
    };

    if (field.date_format) {
      mappedField.dateFormat = field.date_format;
    }

    if (field.is_standalone_question) {
      mappedField.isStandaloneQuestion = field.is_standalone_question;
    }

    return mappedField;
  };

  groups.forEach((group) => {
    const fields = group.fields.filter(filterFields).map(mapField);

    const formFieldValues = {};
    const cardDocumentsDict = {};
    fields.forEach((field) => {
      if (field.value) {
        formFieldValues[field.id] = { value: field.value };
      }
      field.documents.forEach((doc) => {
        cardDocumentsDict[doc.id] = doc;
      });
    });

    cards.push({
      id: group.gid,
      name: group.label,
      relatedContactId: group.related_contact_id,
      type: group.entity_type || '',
      formValue: {
        fields: formFieldValues,
      },
      fields,
      documents: Object.values(cardDocumentsDict),
    });
  });

  return cards;
};

export const mapProject = (project) => {
  return {
    id: project.id,
    identifier: getDocumentIdentifier(project.id),
    fprint: project.fprint,
    matterId: project.matter_id,
    // clientId: project.client_id,
    documents: mapProjectDocuments(project.documents),
    name: project.title,
    updatedAt: convertUpdatedAt(project.last_modified),
  };
};

/* V2 Tags */

export const mapV2TagFromApi = (apiTagRes) => {
  return {
    templateId: `${apiTagRes.template_id}`,
    tag: `${apiTagRes.tag}`,
    // identifier: getV2TagIdentifier(apiTagRes["tag"]),
    entityType: apiTagRes.entity_type,
    entityLabel: apiTagRes.label,
    fieldLabel: apiTagRes.field_label,
    fieldType: apiTagRes.field_type,
    // prompt: apiTagRes["prompt"],
    source: apiTagRes.attribute_source,
  };
};

/* Questionnaires/Intakes */
export const mapQuestionToApi = (question, index) => {
  return {
    id: /^\d+$/.test(question.id) ? parseInt(question.id) : null,
    question_text: question.label,
    question_type: question.type,
    sort_order: index + 1 || question.sortOrder,
    question_hint: question.hint || '',
    tag_str: question.tag || '',
    tag_id: question.tagId || null,
    required: question.required,
    question_data: {
      options: question.options,
      upload_limit: question.uploadLimit,
      upload_types: question.uploadTypes,
    },
  };
};

export const mapQuestionGroupToApi = (questionGroup) => {
  const group = {
    ...(questionGroup.id && {
      id: /^\d+$/.test(questionGroup.id) ? parseInt(questionGroup.id) : null,
    }),
    ...(questionGroup.sortOrder && { sort_order: questionGroup.sortOrder }),
    ...(questionGroup.name && { group_name: questionGroup.name }),
    ...(questionGroup.questions && {
      questions: sortBy(questionGroup.questions, ['sortOrder']).map(
        mapQuestionToApi,
      ),
    }),
    ...(questionGroup.description && {
      description: questionGroup.description,
    }),
    ...{ video_url: questionGroup.videoUrl || '' },
  };

  return group;
};

export const mapQuestionnaireToApi = (questionnaire) => {
  const {
    id,
    title,
    description,
    displayTitle,
    displayDescription,
    questionGroups,
    templateIds,
    templateSetIds,
  } = questionnaire;

  const apiPayload = {
    ...(id && { id: parseInt(id) }),
    ...(title && { name: title }),
    ...(displayTitle && { display_name: displayTitle }),
    ...(displayDescription && { display_description: displayDescription }),
    ...(description && { description }),
    ...(questionGroups && {
      question_groups: sortBy(questionGroups, ['sortOrder']).map(
        mapQuestionGroupToApi,
      ),
    }),
    ...(templateIds && { template_ids: templateIds.map(parseInt) }),
    ...(templateSetIds && { template_set_ids: templateSetIds.map(parseInt) }),
  };

  return apiPayload;
};

export const mapQuestionnaireQuestionFromApi = ({
  id,
  question_text,
  question_type,
  sort_order,
  tag_str,
  tag_id,
  question_data,
  required,
}) => {
  const questionData = question_data || {};

  return {
    id: `${id}`,
    sortOrder: sort_order,
    type: question_type,
    label: question_text,
    tag: tag_str,
    tagId: tag_id || null,
    required,
    options: questionData.options,
    uploadLimit: questionData.upload_limit,
    uploadTypes: questionData.upload_types,
  };
};

export const mapQuestionGroupFromApi = (
  { id, sort_order, group_name, questions, description, video_url },
  index,
) => ({
  id: `${id}`,
  identifier: getQuestionnaireQuestionGroupIdentifier(id),
  sortOrder: sort_order || index + 1,
  name: group_name,
  questions: questions.map(mapQuestionnaireQuestionFromApi),
  description: description || '',
  videoUrl: video_url || '',
});

export const mapQuestionnaireFromApi = ({
  id,
  name,
  description,
  display_name,
  display_description,
  question_groups,
  template_ids,
  template_set_ids,
}) => {
  return {
    id: `${id}`,
    identifier: getQuestionnaireIdentifier(id),
    title: name || '',
    description: description || '',
    displayTitle: display_name || '',
    displayDescription: display_description || '',

    ...(question_groups && {
      questionGroups: sortBy(question_groups.map(mapQuestionGroupFromApi), [
        'index',
      ]),
    }),
    ...(template_ids && {
      templateIds: template_ids.map((id) => `${id}`) || [],
    }),
    ...(template_set_ids && {
      templateSetIds: template_set_ids.map((id) => `${id}`) || [],
    }),
  };
};

export const mapQuestionnaireResponseAnswer = ({
  answer_id,
  answer_text,
  question_id,
  question_text,
  question_type,
  question_hint,
  question_data,
  tag_str,
}) => ({
  id: `${answer_id}`,
  questionId: `${question_id}`,
  answerText: answer_text,
  questionText: question_text,
  questionHint: question_hint,
  questionData: question_data,
  type: question_type,
  tag: tag_str,
});

const mapQuestionnaireResponseAnswerGroup = (
  {
    id,
    sort_order,
    group_name,
    answers,
    // intake_form,
    // organization,
    // video_url,
    // description,
  },
  index,
) => ({
  id: `${id}`,
  sortOrder: sort_order || index + 1,
  name: group_name,
  answers: !answers ? [] : answers.map(mapQuestionnaireResponseAnswer),
});

export const mapQuestionnaireResponseFromApi = ({
  email_address,
  phone_number,
  id,
  intake_form_id,
  matter_id,
  name,
  sent_at,
  started_at,
  submitted_at,
  answers,
  question_group_answers,
}) => ({
  id: `${id}`,
  identifier: getQuestionnaireResponseIdentifier(id),
  email: email_address,
  phoneNumber: phone_number,
  questionnaireId: `${intake_form_id}`,
  matterId: `${matter_id}`,
  name,
  sentAt: sent_at,
  startedAt: started_at,
  submittedAt: submitted_at,
  answers: !answers ? [] : answers.map(mapQuestionnaireResponseAnswer),
  answerGroups: !question_group_answers
    ? []
    : question_group_answers.map(mapQuestionnaireResponseAnswerGroup),
});

export const mapQuestionnaireResponseAnswerToApi = (answer) => ({
  id: answer.id,
  question_id: answer.questionId,
  answer_text: answer.answerText,
  question_text: answer.questionText,
  tag_str: answer.tag,
});

export const mapQuestionnaireResponseToTable = (questionnaire) => ({
  id: questionnaire.id,
  email: questionnaire.email || questionnaire.phoneNumber,
  sentAt: questionnaire.sentAt,
  submittedAt: getFormattedDateFromIsoString(questionnaire.submittedAt),
});

/* Template Sets */
export const mapTemplateSet = ({
  id,
  title,
  templates,
  last_modified,
  created_at,
  templates_with_values,
}) => ({
  id,
  identifier: getTemplateSetIdentifier(id),
  title,
  lastModified: last_modified,
  createdAt: created_at,
  templates: templates.map(getTemplateIdentifier),
  templatesWithValues: templates_with_values.map(
    getTemplatesWithValuesIdentifiers,
  ),
});

/* Coded Documents */
export const mapCodedDocument = ({
  created_at,
  docid,
  document_type,
  fprint,
  last_modified,
  ms_word_template,
  // preview_pages,
  subtitle,
  template_docid,
  title,
}) => ({
  subtitle,
  title,
  fprint,
  id: docid,
  createdAt: created_at,
  documentType: document_type,
  lastModified: last_modified,
  msWordTemplate: ms_word_template,
  templateDocid: template_docid,
});

/* Organizations */
export const mapOrganizationCoupons = ({ coupon, start, end }) => {
  if (!coupon) {
    return {};
  }

  return {
    couponId: coupon.id,
    couponDuration: coupon.duration,
    couponDurationInMonths: coupon.duration_in_months,
    couponMaxRedemptions: coupon.max_redemptions,
    couponPercentOff: coupon.percent_off,
    couponValid: coupon.valid,
    couponStart: start,
    couponEnd: end || null,
  };
};

export const mapOrganizationSubscription = ({
  plan,
  current_period_end,
  canceled_at,
  quantity,
  status,
  customer,
  trial_start,
  trial_end,
  is_active_free_trial,
}) => {
  let _plan = {};
  if (Array.isArray(plan)) {
    let amount = 0;
    let name = '';
    let id = '';
    plan.forEach((p, index) => {
      if (p) {
        amount += (Number(p.fields.amount) * Number(p.fields.quantity)) / 100;
        if (index === plan.length - 1) {
          name += p.fields.name;
          id += p.fields.plan_id;
        } else {
          name += `${p.fields.name}, `;
          id += `${p.fields.plan_id}, `;
        }
      }
    });
    _plan = {
      amount,
      name,
      id,
      interval: plan[0] && plan[0].fields.interval,
    };
  } else {
    _plan = {
      amount: plan && plan.amount,
      name: plan && plan.name,
      id: plan && plan.id,
      interval: plan && plan.interval,
    };
  }
  return {
    plan: _plan,
    canceledAt: canceled_at,
    ccId: customer && customer.card && customer.card.last4,
    currentPeriodEnd: current_period_end,

    // quantity: quantity + free_licenses,
    quantity,

    paidQuantity: quantity,
    status,
    trialStart: trial_start,
    trialEnd: trial_end,
    isActiveFreeTrial: is_active_free_trial,
  };
};

export const mapOrganizationInvoice = ({ date, invoice_pdf, total }) => ({
  date,
  invoice_pdf,
  total,
});

export const mapOrganizationReceipt = ({
  created,
  reciept_url,
  amount_captured,
}) => ({
  created,
  reciept_url,
  amount_captured,
});

/* Memberships */
export const mapMember = ({
  admin,
  can_share,
  display_name,
  email,
  id,
  invitation_pending,
}) => ({
  id,
  admin,
  email,
  canShare: can_share,
  displayName: display_name,
  invitationPending: invitation_pending,
});

export const mapMembership = ({
  id,
  admin,
  email,
  initials,
  is_default,
  enable_dev_tools,
  is_non_profit,
  subscription_id,
  clio_integration_enabled,
  last_org_clio_sync,
  organization_name,
  signup_source,
  profile_pic_url,
  display_name,
  org_id,
  fprint,
  num_free_licenses,
}) => ({
  uid: getMembershipIdentifier(id),
  fprint,
  id,
  admin,
  email,
  initials,
  orgId: org_id,
  subscriptionId: subscription_id,
  isDefault: is_default,
  enableDevTools: enable_dev_tools,
  isNonProfit: is_non_profit,
  organizationName: organization_name,
  signupSource: signup_source,
  avatar: profile_pic_url,
  displayName: display_name,
  clioIntegrationEnabled: clio_integration_enabled,
  clioLastSync: last_org_clio_sync,
  numFreeLicenses: num_free_licenses,
});

export const mapMembershipProfile = ({
  id,
  fprint,
  initials,
  display_name,
  first_name,
  middle_name,
  last_name,
  profile_pic_url,
  auth_user_phone_number,
  otp_enabled,
  otp_method,
  authenticated_user_id,
  xfdf_signature,
}) => ({
  id,
  fprint,
  initials,
  phone: auth_user_phone_number,
  displayName: display_name,
  firstName: first_name,
  lastName: last_name,
  middleName: middle_name,
  avatar: profile_pic_url,
  xfdfSignature: xfdf_signature,
  ssoMethod: otp_method,
  ssoEnabled: otp_enabled,
  authenticatedUserId: authenticated_user_id,
});

/* Contacts */
export const mapContact = ({
  id,
  contact_type,
  role,
  contacts,
  data,
  ...otherProps
}) => {
  const contact = contacts && contacts[0] ? contacts[0] : {};

  const fields = {
    ...keysToCamel(contact),
    ...keysToCamel(otherProps),
    ...keysToCamel(data),
  };

  return {
    id,
    role,
    identifier: getContactIdentifier(id),
    type: contact_type,
    fields,
  };
};

/* Matter */
export const mapMatter = ({
  created_at,
  id,
  description,
  created_by,
  last_modified,
  last_synced,
  ext_matter_id,
  num_documents,
  matter_number,
  organization_id,
  title,
}) => {
  const matter = {
    id,
    title,
    identifier: getMatterIdentifier(id),
    description,
    externalMatterId: ext_matter_id,
    createdAt: created_at,
    lastModified: last_modified,
    lastSynced: last_synced,
    matterNumber: matter_number,
    documentCount: num_documents,
    organizationId: organization_id,
  };

  if (created_by) {
    matter.createdBy = created_by;
  }

  return matter;
};

export const mapMatterDetails = ({
  v2_data,
  data,
  related_contacts,
  attached_projects,
}) => {
  const relatedContacts = related_contacts.map(({ org_contact, role }) =>
    mapContact({
      ...org_contact,
      role,
    }),
  );

  const attachedContacts = uniqBy(relatedContacts, 'id');
  const attachedProjects = attached_projects.map(mapProject);

  const documentSets = attached_projects.filter(
    (project) => project.documents && project.documents.length > 1,
  );
  const singleDocuments = attached_projects.filter(
    (project) => project.documents && project.documents.length === 1,
  );

  const details = {
    data,
    v2Data: v2_data,
    documentSets: documentSets.map(({ id }) => getDocumentIdentifier(id)),
    documents: singleDocuments.map(({ id }) => getDocumentIdentifier(id)),
    relatedContacts: attachedContacts.map(({ id }) => getContactIdentifier(id)),
  };

  return {
    details,
    attachedContacts,
    attachedProjects,
  };
};

/* Document Sets */
export const mapDocumentSetToTable = (docSet) => ({
  id: docSet.id,
  fprint: docSet.fprint,
  title: docSet.name,
  updatedAt: docSet.updatedAt,
  documents: docSet.documents,
  numDocs: docSet.documents ? docSet.documents.length : 0,
});

/* Documents */
export const mapDocumentToTable = (document) => ({
  id: document.id,
  title: document.name,
  updatedAt: document.updatedAt,
  documentType: document.documents?.[0]?.documentType,
  docx:
    document.documents && document.documents[0] && document.documents[0].docx,
  processedDocx:
    document.documents &&
    document.documents[0] &&
    document.documents[0].processedDocx,
  processedMsPdf:
    document.documents &&
    document.documents[0] &&
    document.documents[0].processedMsPdf,
});

export const mapDocument = (document) => {
  const docx =
    document.documents && document.documents[0]
      ? document.documents[0].ms_word_document
      : null;
  const pdf =
    document.documents && document.documents[0]
      ? document.documents[0].ms_word_document_pdf
      : null;
  const processedDocx =
    document.documents && document.documents[0]
      ? document.documents[0].ms_word_document_processed
      : null;
  const processedMsPdf =
    document.documents && document.documents[0]
      ? document.documents[0].ms_word_document_processed_pdf
      : null;

  return {
    id: document.id,
    documentType: document.document_type,
    identifier: getDocumentIdentifier(document.id),
    fprint: document.fprint,
    clientEmail: document.client_email,
    // clientId: document.client_id,
    clientName: document.client_name,
    county: document.county,
    extra: document.extra,
    isProSe: document.is_pro_se,
    isStarred: document.is_starred,
    projectType: document.project_type,
    searchText: document.search_text,
    status: document.status,
    title: document.title,
    docx,
    pdf,
    processedDocx,
    processedMsPdf,
    updatedAt: convertUpdatedAt(document.last_modified),
  };
};

/* Templates */
export const mapTemplate = (template) => ({
  createdAt: template.created_at,
  identifier: getTemplateIdentifier(template.docid),
  id: `${template.docid}`,
  docid: template.docid,
  documentType: template.document_type,
  fprint: template.fprint,
  msWordTemplate: template.ms_word_template,
  pdfBackendDocid: template.pdf_backend_doc_id,
  previewPages: keysToCamel(template.preview_pages || []),
  subtitle: template.subtitle,
  templateDocid: template.template_docid,
  title: template.title,
  documentDrafts: [],
  updatedAt: convertUpdatedAt(template.last_modified),
  isBaseTemplate: template.is_base_template,
  includes: keysToCamel(template.includes || []),
});

export const mapPdfCourtForm = (hit) => ({
  identifier: getTemplateIdentifier(hit.document.id),
  id: hit.document.id,
  filePath: hit.document.file_path,
  fprint: hit.document.fprint,
  filterCategory: hit.document.filter_category,
  locality: hit.document.locality,
  title: hit.document.title,
  subtitle: hit.document.subtitle,
  documentCategoryId: hit.document.document_type,
  legalDocumentType: hit.document.legal_document_type,
  legalPracticeArea: hit.document.legal_practice_area,
  isDeleted: hit.document.is_deleted,
});

// grouped_hit.group_key === [${filterCategory}, ${locality}]
// e.g. ["CA", "LA"]
export const mapLocality = (grouped_hit) => ({
  identifier: getLocalityIdentifier(grouped_hit.group_key.join('-')),
  filterCategory: grouped_hit.group_key[0],
  locality: grouped_hit.group_key[1],
});

export const mapSignaturePackageDocumentPages = (documentPages) => {
  return documentPages.map((documentPage) => {
    return {
      id: documentPage.id,
      pageNumber: documentPage.page_number,
      backgroundUrl: documentPage.background_url,
      elements: documentPage.elements.map((element) =>
        mapSignaturePackageElement(element),
      ),
    };
  });
};

export const mapSignaturePackageDocuments = (signaturePackageDocuments) => {
  return signaturePackageDocuments.map((signaturePackageDocument) => {
    return {
      id: signaturePackageDocument.id,
      title: signaturePackageDocument.title,
      subtitle: signaturePackageDocument.subtitle,
      sortOrder: signaturePackageDocument.sort_order,
      templateId: signaturePackageDocument.template_id,
      originalFileUrl: signaturePackageDocument.original_file_url,
      pages: mapSignaturePackageDocumentPages(signaturePackageDocument.pages),
    };
  });
};

export const mapAuditTrail = (auditTrail) => ({
  actionText: auditTrail.action_text,
  ipAddress: auditTrail.ip_address,
  recipient: mapRecipient(auditTrail.recipient),
  timestamp: auditTrail.timestamp,
  timezoneOffset: auditTrail.timezone_offset,
});

export const mapSignaturePackage = (signaturePackage) => {
  const signaturePackageData = {
    id: signaturePackage.id,
    identifier: getSignaturePackageIdentifier(signaturePackage.id),
    lawyawProjectId: signaturePackage.lawyaw_project_id,
    lawyawClientId: signaturePackage.lawyaw_client_id,
    isActive: signaturePackage.is_active,
    isUnifiedEsign: signaturePackage.is_unified_esign,
    fprint: signaturePackage.fprint,
    createdAt: getFormattedDateFromIsoString(signaturePackage.created_at_ts),
    updatedAt: signaturePackage.updated_at,
    packageStatus: signature_status_map[signaturePackage.package_status],
    lawyawDocumentIds: signaturePackage.lawyaw_document_ids,
    whoSigns: signaturePackage.who_signs,
    freeze: signaturePackage.freeze,
    title: signaturePackage.title,
    customOrder: signaturePackage.custom_order,
    currentStep: signaturePackage.current_step,
    emailSubject: signaturePackage.email_subject,
    emailBody: signaturePackage.email_body,
    emailCC: signaturePackage.email_cc,
    lawyawDocuments: mapSignaturePackageDocuments(
      signaturePackage.lawyaw_documents,
    ),
  };
  if (signaturePackage.audit_trail) {
    signaturePackageData.auditTrail = signaturePackage.audit_trail.map(
      (audit_trail) => mapAuditTrail(audit_trail),
    );
  }
  if (signaturePackage.recipients) {
    signaturePackageData.recipients = signaturePackage.recipients.map(
      (recipient) => mapRecipient(recipient),
    );
  }
  return signaturePackageData;
};

export const mapTemplateVariableFromApi = ({
  attribute_source,
  // normalized_attribute,
  // normalized_entity,
  prompt,
  tag,
  variable_type,
}) => ({
  source: attribute_source || '',
  type: variable_type,
  prompt,
  tag,
});

export const mapTemplateDefaultValues = ({
  id,
  title,
  values,
  organization_id,
  template,
  pdf_template_tag_instances,
  created_at,
  last_modified,
}) => ({
  identifier: getTemplateDefaultValuesIdentifier(id),
  id,
  title,
  values,
  organizationId: organization_id,
  template: {
    docId: template?.docid,
    templateDocId: template?.template_docid,
    title: template?.title,
    subtitle: template?.subtitle,
    originalFile: template?.original_file,
  },
  pdfTemplateTagInstances: pdf_template_tag_instances?.map((ptti) => {
    return {
      tagStr: ptti.tag_str,
      id: ptti.id,
      top: ptti.top,
      left: ptti.left,
      height: ptti.height,
      width: ptti.width,
      lineHeight: ptti.line_height,
      label1: ptti.label1,
      label2: ptti.label2,
      label3: ptti.label3,
      hint: ptti.hint,
      input_type_from_pdf: ptti.input_type_from_pdf,
      pageNumber: ptti.page_number,
      computedFieldType: ptti.computed_field_type,
      computedFieldTag: ptti.computed_field_tag,
      pdfElementID: ptti.pdf_element_id,
      tag: ptti.tag_str,
    };
  }),
  createdAt: getFormattedDateFromIsoString(created_at),
  lastModified: getFormattedDateFromIsoString(last_modified),
});

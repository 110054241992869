import { http } from '~/src/services/requests';

function cbsSubscriptionsService() {
  return {
    getPaymentMethodUpdateUrl: async (orgFprint: string) => {
      return http.innerSendAuthenticated(
        {
          relativePath: `/cbs_subscriptions/org/${orgFprint}/payment-method-update-link/`,
          method: 'GET',
        },
        true,
      );
    },
  };
}

export default cbsSubscriptionsService();

import {
  Box,
  Radio,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormField } from '~/src/components/FormField';
import {
  CancellationReasons,
  CANCELLATION_REASONS,
} from '~/src/entities/subscription';

type CancelSubscriptionReasonProps = {
  value: CancellationReasons;
  onChange: (reasons: CancellationReasons) => void;
};

export const CancelSubscriptionReason = ({
  value,
  onChange,
}: CancelSubscriptionReasonProps) => {
  const handleCheckboxChange = (name: string, nextCheckedValue: boolean) => {
    const nextValues: { [key: string]: boolean | string } = { ...value };
    if (nextCheckedValue === true) {
      Object.keys(nextValues).forEach((key: string) => {
        if (typeof nextValues[key] === 'boolean') {
          nextValues[key] = false;
        }
      });

      nextValues[name] = true;
    }

    onChange(nextValues as CancellationReasons);
  };

  return (
    <>
      <Box pb={2}>
        <Typography>
          Before you cancel please let us know the reason you are leaving
        </Typography>
      </Box>
      <Box pb={1}>
        <FormGroup>
          {CANCELLATION_REASONS.map(({ name, label }) => {
            const isChecked = value[name];
            return (
              <FormControlLabel
                key={name}
                sx={{ margin: 0, paddingY: 0.5 }}
                control={
                  <Radio
                    size={'small'}
                    onChange={() => handleCheckboxChange(name, !isChecked)}
                    value={name}
                    checked={isChecked}
                  />
                }
                label={
                  <Box pl={1}>
                    <Typography variant={'label'}>{label}</Typography>
                  </Box>
                }
              />
            );
          })}
        </FormGroup>
      </Box>
      <FormField label={'Other (please explain)'}>
        <TextField
          multiline
          rows={2}
          value={value.other || ''}
          onChange={(e) => onChange({ ...value, other: e.target.value })}
          name={'other'}
        />
      </FormField>
    </>
  );
};

import moment from 'moment';

export function getNewFormattedDate(): string {
  return formatDate(new Date());
}

/**
 * Formats a given ISO-8601 string date into a full date format based on the user's locale.
 * Example (US locale): "2025-10-01" -> "October 01, 2025"
 * @param isoDateString - The date string in ISO-8601 format.
 * @returns The formatted full date string.
 */
export function getFormattedFullDateFromIsoString(
  isoDateString: string,
): string {
  if (!isValidIsoDateString(isoDateString)) return '';

  const date = parseDateTime(isoDateString);
  const formatter = new Intl.DateTimeFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  );

  return formatter.format(date);
}

/**
 * Formats a given ISO-8601 string date into a short date format based on the user's locale.
 * Example (US locale): "2025-10-01" -> "10/01/2025"
 * @param isoDateString - The date string in ISO-8601 format.
 * @returns The formatted short date string.
 */
export function getFormattedDateFromIsoString(isoDateString: string): string {
  if (!isValidIsoDateString(isoDateString)) return '';

  const date = parseDateTime(isoDateString);
  return formatDate(date);
}

/**
 * Formats a given ISO-8601 string into a 'hh:MM a' time format, adjusting for the user's timezone.
 * Example: "2023-10-01T14:30:00.000Z" (UTC) -> "9:30 AM" (America/Toronto, UTC-5)
 * @param isoTimeString - The datetime string in ISO-8601 format.
 * @returns The formatted time string in the user's timezone.
 */
export function getFormattedTimeFromIsoString(isoTimeString: string): string {
  if (!isValidIsoDateString(isoTimeString)) return '';

  const date = parseDateTime(isoTimeString);

  const formatter = new Intl.DateTimeFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  );

  return formatter.format(date);
}

export function formatDate(date: Date): string {
  const formatter = getDateFormatter();
  return formatter.format(date);
}

export function getLocalDateFormat() {
  const formatter = getDateFormatter();
  const parts = formatter.formatToParts(new Date());
  const separator = parts.find((part) => part.type === 'literal')?.value || '/';

  const formatOrder = parts
    .filter((part) => ['day', 'month', 'year'].includes(part.type))
    .map((part) => {
      if (part.type === 'year') return 'YYYY';
      if (part.type === 'month') return 'MM';
      if (part.type === 'day') return 'DD';
    })
    .join(separator);

  return formatOrder;
}

function isValidIsoDateString(dateString: string): boolean {
  return moment(dateString, moment.ISO_8601, true).isValid();
}

function parseDateTime(stringDate: string): Date {
  return moment(stringDate).toDate();
}

function getDateFormatter(): Intl.DateTimeFormat {
  return new Intl.DateTimeFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  );
}

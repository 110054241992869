/* Libs */
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

/* Containers */
import App from '~/src/containers/App';

/* Utilities */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mobx-react-lite/batchingForReactDom';

/* Styles */
import './index.css';
import 'react-day-picker/lib/style.css';
import 'tailwindcss/tailwind.css';
import env from './utils/env';

Sentry.onLoad(() => {
  if (env.sentryDsn) {
    // When running locally we don't want to send message to Sentry in order to
    // stay below our quota.
    // eslint-disable-next-line no-console
    console.log('Sentry is not enabled in this context. No DSN set.');
    return;
  }

  try {
    Sentry.init({
      dsn: env.sentryDsn,
      release: env.sentryCommitSha,
      environment: env.sentryEnvironment,
      denyUrls: ['localhost'],
      ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
      ],
    });
  } catch (error) {
    console.error('Sentry failed to initialize', error);
  }
});

const nativeErrorLog = console.error;
console.error = (...args) => {
  const name = args[0];
  const error = args[1] || new Error(name);

  if (!env.disableSentry) {
    Sentry.captureException(error);
  }

  nativeErrorLog(...args);
};

ReactDOM.render(<App />, document.getElementById('root'));

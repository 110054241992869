import { useState } from 'react';

const defaultValidation = () => {
  return true;
};

const defaultState = {
  value: '',
  valid: true,
  touched: false,
  focus: false,
  validation: defaultValidation,
};

function useField(state) {
  const [validation] = useState(() => {
    return state.validation || defaultState.validation;
  });
  const [error, setError] = useState(null);

  const [value, setValueState] = useState(() => {
    return typeof state.value !== 'undefined'
      ? state.value
      : defaultState.value;
  });

  const [valid, setValidState] = useState(() => {
    const value =
      typeof state.value !== 'undefined' ? state.value : defaultState.value;

    return state.validation
      ? state.validation({ touched: false, value, focused: false })
      : true;
  });

  const [touched, setTouched] = useState(defaultState.touched);
  const [focus, setFocus] = useState(defaultState.focus);

  function setValue(value, validationProps) {
    const isValid = validation({ touched, focus, value, ...validationProps });

    if (typeof isValid === 'string') {
      setError(isValid);
    } else {
      setError(null);
    }

    setValueState(value);
    setTouched(true);
    setValidState(isValid === true);
  }

  return {
    value,
    focus,
    touched,
    valid,
    error,
    setValue,
    setTouched,
    setFocus,
  };
}

export default useField;

import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getRelatedQuestionnaireSubmissionIdUrl } from '../urls';
import { questionnaireQueryArgs } from '../queryArgs';

type Variables = {
  id: number;
};

export const useDeleteRelatedQuestionnaireSubmission = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, APIError, Variables>({
    onSuccess: () => {
      queryClient.invalidateQueries(questionnaireQueryArgs.all);
    },
    mutationFn: async (payload) => {
      const response = await fetch(
        getRelatedQuestionnaireSubmissionIdUrl(orgFprint, payload.id),
        {
          method: 'DELETE',
        },
      );

      if (!response.ok) {
        throw new APIError(
          'Failed to delete related questionnaire submission in lawyaw',
          response,
        );
      }
    },
  });
};

import { useQuery } from 'react-query';
import { useCurrentOrgFprint } from '../../user';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { getQuestionnaireEmbeddedEligibility } from '../urls';

export interface QuestionnaireEmbeddedElibilityResponse {
  is_eligible_for_embedded: boolean;
}

export const useGetQuestionnaireEmbeddedEligibility = () => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts({
      queryKey: ['embedded-eligibility', orgFprint],
      queryFn: () =>
        fetchAndParse<QuestionnaireEmbeddedElibilityResponse>(
          getQuestionnaireEmbeddedEligibility(orgFprint),
        ),
    }),
  );
};

import { PrepareDocument } from '@clio/hancock';
import { AssignedAnnotationType } from '@clio/hancock/dist/prepare/constants';
import { AnnotationEventMetadata } from '@clio/hancock/dist/prepare/events';
import { getNewFormattedDate } from '~/src/utils/date';
import {
  SignatureBox,
  SignedWithImage,
  DateBox,
  Signers,
  Dated,
  Assignee,
  Pdf,
  Signer,
  InputType,
} from '~/src/models';

/**
 * Indicates whether a given element is an signature (as opposed
 * to an assignment or date)
 *
 * @param element – The element that we want to know about
 * @returns {boolean}
 */
const getIsSignature = (element: SignatureBox): element is SignedWithImage =>
  element.type === 'signed-with-image';

/**
 * Indicates whether a given element is a signature with XFDF (as
 * opposed to a PNG signature, which shouldn't appear during the
 * signer selection flow)
 *
 * @param element – The element that we want to know about
 * @returns {boolean}
 */
const getIsXfdfSignatureElement = (
  element: SignatureBox,
): element is SignedWithImage =>
  getIsSignature(element) && !!element.recipient.xfdfSignature;

/**
 * Indicates whether a given element is an date (as opposed
 * to an assignment or signature)
 *
 * @param element – The element that we want to know about
 * @param signers – The signers on the document
 * @returns
 */
const getIsDateElement = (
  element: DateBox,
  signers: Signers,
): element is Dated =>
  'me' in signers &&
  element.type === 'dated' &&
  element.recipient &&
  element.recipient.email === signers.me.email;

/**
 * Indicates whether a given element is an assignment (as opposed
 * to a date or signature)
 *
 * @param element – The element that we want to know about
 * @param signers – The signers on the document
 */
const getIsOtherRecipientSignatureOrDateAssignmentElement = (
  element: SignatureBox | DateBox,
  signers: Signers,
): element is Assignee =>
  element.type === 'assignee' &&
  (!('me' in signers) || element.recipient!.email !== signers.me.email);

/**
 * Maps a signature element to a signature location
 * that hancock can use to render the attorney's
 * signatures on the document
 *
 * @param signature - The element that we want to convert
 */
const mapSignatureToHancockSignatureLocation = (
  signature: SignedWithImage,
) => ({
  x: signature.left,
  y: signature.top,
  pageNumber: signature.pageNumber,
  width: signature.imageWidth,
  height: signature.imageHeight,
  metadata: {
    // Hancock requires metadata key/values to be string -> string
    id: signature.id!.toString(),
  },
});

/**
 * Maps a date element to a date location
 * that hancock can use to render the attorney's
 * dates on the document
 *
 * @param date - The date we want to convert
 * @returns
 */
const mapDateElementToHancockDateLocation = (date: Dated) => ({
  x: date.left,
  y: date.top,
  pageNumber: date.pageNumber,
  width: date.width,
  height: date.height,
  date: date.value,
  metadata: {
    // Hancock requires metadata key/values to be string -> string
    id: date.id!.toString(),
  },
});

/**
 * Maps a signer's element to an assignment location
 * that hancock can use to render all assignments to
 * other signers in the prepare flow
 *
 * @param element - The assignment element we want to convert
 * @returns
 */
const mapOtherRecipientAssignmentElementToHancockLocation = (
  element: Assignee,
) => ({
  x: element.left,
  y: element.top,
  pageNumber: element.pageNumber,
  assigneeEmail: element.recipient.email,
  metadata: {
    // Hancock requires metadata key/values to be string -> string
    id: element.id!.toString(),
  },
});

/**
 * Converts a signature package PDF from our lawyaw format
 * to the format that Hancock requires in order to load a
 * document in the signer selection flow.
 *
 * @param pdf - The PDF that we want Hancock to render
 * @param signers - The signers in the package
 * @param mySignatureXfdf - The attorney's signature, if any
 */
export const convertSignaturePackageToPrepareDocument = (
  pdf: Pdf,
  signers: Signers,
  mySignatureXfdf?: string,
): PrepareDocument => {
  const { id, bodyData, signatures, dates } = pdf;
  const result: PrepareDocument = {
    id,
    /**
     * hancock doesn't accept blobs right now in the typedef, but does work with
     * them. some testing issues prevent us making blobs supported in the typedef
     * at the moment and it's really quite annoying.
     */
    // @ts-expect-error
    documentPath: 'href' in bodyData ? bodyData.href : bodyData,
    otherRecipientSignatureLocations: [],
    otherRecipientDateLocations: [],
  };

  if ('me' in signers) {
    result.myEmail = signers.me.email;
  }

  if (mySignatureXfdf && 'me' in signers) {
    result.mySignatureXfdf = mySignatureXfdf;
  }

  result.mySignatureLocations = signatures
    .filter(getIsSignature)
    .map(mapSignatureToHancockSignatureLocation);

  result.myDates = dates
    .filter((e): e is Dated => getIsDateElement(e, signers))
    .map(mapDateElementToHancockDateLocation);

  result.otherSignerEmails =
    'others' in signers ? signers.others.map((e) => e.email) : [];

  result.otherRecipientSignatureLocations = signatures
    .filter((each): each is Assignee =>
      getIsOtherRecipientSignatureOrDateAssignmentElement(each, signers),
    )
    .map(mapOtherRecipientAssignmentElementToHancockLocation);

  result.otherRecipientDateLocations = dates
    .filter((each): each is Assignee =>
      getIsOtherRecipientSignatureOrDateAssignmentElement(each, signers),
    )
    .map(mapOtherRecipientAssignmentElementToHancockLocation);

  return result;
};

/**
 * Makes the element associated with an attorney assigning a field
 * to someone else during the signer selection flow
 *
 * @param signer - The signer for this element
 * @param type - The type of element
 * @param metadata - The annotation metadata (x/y/etc)
 * @param pdf - The PDF to which this element belongs
 * @param id - The ID of this element (if it exists on the backend already)
 */
export const makeAssignmentElementPayload = (
  signer: Signer,
  type:
    | AssignedAnnotationType.SignatureAssignment
    | AssignedAnnotationType.DateAssignment,
  metadata: AnnotationEventMetadata,
  pdf: Pdf,
  id?: number,
): Assignee => {
  const { x, y, width, height, pageNumber } = metadata;
  const signaturePageId = Number(pdf.pageIdsByPageNumber[pageNumber]);
  if (type === AssignedAnnotationType.SignatureAssignment) {
    const assignee: Assignee = {
      type: 'assignee',
      inputType: InputType.SignatureBlock,
      signaturePageId,
      pageNumber,
      left: x,
      top: y,
      height,
      width,
      recipient: signer,
    };
    if (id) {
      assignee.id = id;
    }
    return assignee;
  }
  const assignee: Assignee = {
    type: 'assignee',
    inputType: InputType.SignatureDate,
    signaturePageId,
    pageNumber,
    left: x,
    top: y,
    height,
    width,
    recipient: signer,
  };
  if (id) {
    assignee.id = id;
  }
  return assignee;
};

/**
 * Makes the element associated with an attorney dating a field
 * during the signer selection flow
 *
 * @param signer - The signer for this element
 * @param type - The type of element
 * @param metadata - The annotation metadata (x/y/etc)
 * @param pdf - The PDF to which this element belongs
 * @param id - The ID of this element (if it exists on the backend already)
 */
export const makeDateNowElement = (
  signer: Signer,
  metadata: AnnotationEventMetadata,
  pdf: Pdf,
  id?: number,
): Dated => {
  const { x, y, width, height, pageNumber } = metadata;
  const signaturePageId = Number(pdf.pageIdsByPageNumber[pageNumber]);
  const assignee: Dated = {
    type: 'dated',
    inputType: InputType.Textbox,
    signaturePageId,
    pageNumber,
    left: x,
    top: y,
    height,
    width,
    recipient: signer,
    value: getNewFormattedDate(),
  };
  if (id) {
    assignee.id = id;
  }
  return assignee;
};

/**
 * Makes the element associated with an attorney signing a field
 * during the signer selection flow. Used for update operations
 *
 * @param signer - The signer for this element
 * @param type - The type of element
 * @param metadata - The annotation metadata (x/y/etc)
 * @param pdf - The PDF to which this element belongs
 * @param id - The ID of this element (if it exists on the backend already)
 */
export const makeSignatureUpdatedElement = (
  signer: Signer,
  metadata: AnnotationEventMetadata,
  pdf: Pdf,
  xfdfSignature: string,
  id: number,
): SignedWithImage => {
  const { x, y, width, height, pageNumber } = metadata;
  const signaturePageId = Number(pdf.pageIdsByPageNumber[pageNumber]);
  const assignee: SignedWithImage = {
    type: 'signed-with-image',
    inputType: InputType.SignatureBlock,
    signaturePageId,
    pageNumber,
    left: x,
    top: y,
    imageHeight: height,
    imageWidth: width,
    recipient: {
      ...signer,
      xfdfSignature,
    },
  };
  if (id) {
    assignee.id = id;
  }
  return assignee;
};

/**
 * Makes the element associated with an attorney signing a field
 * during the signer selection flow
 *
 * @param signer - The signer for this element
 * @param metadata - The annotation metadata (x/y/etc)
 * @param pdf - The PDF to which this element belongs
 * @param id - The ID of this element (if it exists on the backend already)
 */
export const makeSignNowElement = (
  signer: Signer,
  metadata: AnnotationEventMetadata,
  pdf: Pdf,
  id?: number,
): Assignee => {
  const { x, y, width, height, pageNumber } = metadata;
  const signaturePageId = Number(pdf.pageIdsByPageNumber[pageNumber]);
  const assignee: Assignee = {
    type: 'assignee',
    inputType: InputType.SignatureBlock,
    signaturePageId,
    pageNumber,
    left: x,
    top: y,
    height,
    width,
    recipient: signer,
  };
  if (id) {
    assignee.id = id;
  }
  return assignee;
};

/**
 * Finds the first XFDF signature in the package. This signature
 * can be reused for all of the attorney's signatures in the
 * signature package
 *
 * @param signaturePackage - The signature package containing the signature (if any)
 * @returns
 */
export const findFirstXfdfSignatureInPackage = (
  signaturePackage: Pdf[],
): string | undefined => {
  const signaturesWithXfdf = signaturePackage
    .reduce<SignatureBox[]>((acc, each) => {
      each.signatures.forEach((each) => {
        acc.push(each);
      });
      return acc;
    }, [])
    .filter(getIsXfdfSignatureElement);
  if (!signaturesWithXfdf.length) return undefined;
  return signaturesWithXfdf[0]!.recipient.xfdfSignature;
};

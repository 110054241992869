import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { css } from 'aphrodite';
import styles from './styles';

import useFetchProject from '~/src/hooks/useFetchProject';
import useMst, { useStore } from '~/src/hooks/useMst';
import PageLayout, { LoadingOverlay } from '~/src/components/PageLayout';

import { useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { NAVIGATION_WIDTH } from '~/src/components/PageLayout/styles';
import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';
import { useWebViewerContext } from '~/src/components/Webviewer';
import { Project } from '~/src/models';
import analyticsService from '~/src/services/analytics';
import clioService, {
  StartUploadDocument,
} from '../../services/api/clioService';
import { DraftingFooter } from './DraftingFooter';
import { history } from '../../utils/history';
import { AddDocumentButton } from './AddDocumentButton';
import { SelectionSidebarHeader } from '~/src/components/SelectionSidebar';
import PDFViewer from './PDFViewer';
import { AsyncResultStatus } from '~/src/entities/project/types';
import { useIsCanary } from '~/src/entities/user/hooks/useFeatureAccess';
import {
  useProjectAsyncUpdateState,
  useProjectPopulateState,
} from '~/src/entities/project/hooks';
import { getFileFromUrl } from '~/src/hooks/useBackend.utils';

export type DraftingModalProps = {
  title: string;
  messages: string[];
  onCancel: Function;
};

export type PdfBlob = {
  blobData: Blob;
  numberOfPages: number;
  documentId: number;
};

export const DraftingPage = ({ projectId }: { projectId: number }) => {
  const {
    signatures,
    addDocumentToSelectionSidebar,
    initialize,
    setDocIndex,
    setProjectid,
    setIdentifier,
    clioMatterId,
    clioParentId,
    orgFprint,
  } = useMst((store) => ({
    signatures: store.signatures,
    addDocumentToSelectionSidebar:
      store.documents.addDocumentToSelectionSidebar,
    initialize: store.sidebarItems.initialize,
    setDocIndex: store.sidebarItems.setIndex,
    setProjectid: store.projects.setProjectId,
    setIdentifier: store.sidebarItems.setIdentifier,
    clioMatterId: store.clio.courtFormClioMatterId,
    clioParentId: store.clio.courtFormClioParentId,
    orgFprint: store.user.currentOrganization?.fprint,
  }));

  const [taskId, setTaskId] = useState('');
  const { sidebarItems, clio: clioStore } = useStore();
  const docIndex = useObserver(() => sidebarItems.currentIndex);
  const isCanary = useIsCanary();
  const { data: projectAsyncUpdateState } = useProjectAsyncUpdateState(
    projectId,
    isCanary,
  );
  const canaryDependanciesMet = projectAsyncUpdateState?.isSettled || !isCanary;
  const hasCanaryDependancyError = projectAsyncUpdateState?.hasError === true;

  const { setWebViewerPosition, hideWebViewer, setZIndexOfWebViewer } =
    useWebViewerContext();

  const taskStatusQuery = usePollingTaskStatus(taskId);

  useEffect(() => {
    const fetchData = async () => {
      if (taskStatusQuery?.data) {
        const { status, result } = taskStatusQuery.data;
        if (status === AsyncResultStatus.SUCCESS) {
          if (
            result?.signature_package_id &&
            result?.project_id &&
            result?.signature_package_fprint
          ) {
            await signatures.fetchSignaturePackage(
              result?.project_id,
              result?.signature_package_fprint,
            );
            setTaskId('');
            setSignaturePackageFilesData([]);
            setZIndexOfWebViewer(1);
            setViewerIsLoading(false);
            setCreateEsignPackage(false);

            history.push(
              `/signature-wizard/${result?.project_id}/${result?.signature_package_id}/new`,
            );
          }
        } else if (status === AsyncResultStatus.FAILURE) {
          showModal(LAYOUT_MODAL_TYPES.error, {
            title: 'Error: failed to create E-Sign package',
            messages: [
              'We are unable to create E-Sign package for now. Please check back later.',
            ],
            onCancel: () => {
              setTaskId('');
              setSignaturePackageFilesData([]);
              setZIndexOfWebViewer(1);
              setViewerIsLoading(false);
              setCreateEsignPackage(false);
            },
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatusQuery]);

  useEffect(() => {
    if (clioMatterId) {
      //initiated from ClioManage
      if (clioParentId) {
        // edit mode
        analyticsService.track('Clio Edit Form Set In Lawyaw', {
          clioMatterId,
          clioParentId,
        });
      } else {
        // create mode
        analyticsService.track('Clio Draft New Documents From Lawyaw', {
          clioMatterId,
        });
      }
    }
    setWebViewerPosition({
      top: 69,
      left: NAVIGATION_WIDTH,
      right: 335,
      bottom: 72,
    });
    return () => {
      hideWebViewer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: projectPopulateState } = useProjectPopulateState(
    projectId,
    !isCanary || projectAsyncUpdateState?.isSettled === true,
  );
  const { project, loading }: { project: any; loading: boolean } =
    useFetchProject(projectId, false, true, projectPopulateState.isSettled);
  const isDoneLoading =
    projectPopulateState?.isSettled === true &&
    !loading &&
    canaryDependanciesMet;

  useEffect(() => {
    if (project && project.clioMatterId) {
      clioStore.setCourtFormClioMatterId(project?.clioMatterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.clioMatterId]);

  useEffect(() => {
    if (project && project.clioParentId) {
      clioStore.setCourtFormClioParentId(project?.clioParentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.clioParentId]);

  const { showModal, hideModal, showToast } = useLayoutContext();

  const [fullLoaded, setFullLoaded] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(true);
  const [documents, setDocuments] = useState<Project.Document[]>([]);

  const currentDocument = documents[docIndex - 1];

  const isHighlightingEnabled = !!currentDocument?.docx;
  const [viewerIsLoading, setViewerIsLoading] = useState(false);
  const [requestedDownloadAllPdf, setRequestedDownloadAllPdf] = useState(false);
  const [requestedDownloadPdf, setRequestedDownloadPdf] = useState(false);
  const [allPdfBlobs, setAllPdfBlobs] = useState([]);
  const [createEsignPackage, setCreateEsignPackage] = useState(false);
  const [signaturePackageFilesData, setSignaturePackageFilesData] = useState(
    [],
  );
  const [reRouteToEsign, setReRouteToEsign] = useState(false);
  const [savingInClio, setSavingInClio] = useState(false);
  const [clioDocumentUploadURLs, setClioDocumentUploadURLs] = useState<
    Record<number, StartUploadDocument> | undefined
  >(undefined);
  const [currentPdfBlob, setCurrentPdfBlob] = useState<PdfBlob | undefined>(
    undefined,
  );
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);

  const handleEdit = () => {
    history.replace(`/populate/${projectId}`);
    setZIndexOfWebViewer(1); // restore Webviewer zIndex to 1 since router URL changes
  };

  const handleAllDownload = () => {
    setZIndexOfWebViewer(0);
    setViewerIsLoading(true);
    setRequestedDownloadAllPdf(true);
  };
  const handleCurrentDownload = () => {
    setRequestedDownloadPdf(true);
  };

  const handleCreateEsign = () => {
    setZIndexOfWebViewer(0);
    setViewerIsLoading(true);
    setCreateEsignPackage(true);
  };

  const handleToggleHighlight = () => {
    setIsHighlighted((prevIsHighlighted) => !prevIsHighlighted);
  };

  const [hasPdfTronLoadedDocument, setHasPdfTronLoadedDocument] =
    useState(false);

  const setToFirstDocument = () => {
    setDocIndex(1);
  };

  const handleSaveInClioError = () => {
    analyticsService.track('Save to Clio failed', {
      success: false,
      flow: clioParentId ? 'edit' : 'create',
    });
    showModal(LAYOUT_MODAL_TYPES.error, {
      title: 'Error: failed to save',
      messages: [
        'We are unable to save these documents to Clio. Please check your internet connection and your matter permissions.',
        'To continue, you will need to download a copy from Clio Draft and return to Clio to manually upload them. We apologize for the inconvenience.',
      ],
      onCancel: () => {
        setToFirstDocument();
        setClioDocumentUploadURLs(undefined);
        setSavingInClio(false);
        setZIndexOfWebViewer(1);
        hideModal();
      },
    });
  };

  const handleLoadProjectError = () => {
    showToast(LAYOUT_TOAST_TYPES.error, {
      message: `
      We are unable to load these documents.
      Please check your internet connection and try again
      `,
    });

    history.goBack();
  };

  const handleSaveInClio = async () => {
    setSavingInClio(true);
    setZIndexOfWebViewer(-1);
    setToFirstDocument();
    const startUpload = clioParentId
      ? clioService.startEdit.bind(
          clioService.startEdit,
          orgFprint!,
          projectId,
          clioMatterId!,
          clioParentId,
        )
      : clioService.startUpload.bind(
          clioService.startUpload,
          orgFprint!,
          projectId,
          clioMatterId!,
        );

    try {
      const response = await startUpload();
      setRedirectUrl(response.redirect_url);
      const mappedUrls = response.documents.reduce(
        (obj, doc) => ({ ...obj, [doc.project_document_id]: doc }),
        {},
      );

      setClioDocumentUploadURLs(mappedUrls);
    } catch {
      handleSaveInClioError();
    }
  };

  const redirectToClio = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const markFullyUploaded = () => {
    if (!clioDocumentUploadURLs) {
      return;
    }
    const payload = Object.values(clioDocumentUploadURLs).map((doc) => {
      return { id: doc.id, uuid: doc.latest_document_version.uuid };
    });
    clioService
      .markFullyUploaded(orgFprint!, payload)
      .then(redirectToClio)
      .catch(handleSaveInClioError);
  };

  const createSignaturePackage = async () => {
    const createdSignaturePackage = await signatures.createSignaturePackage(
      signaturePackageFilesData,
      project.id,
    );
    if (createdSignaturePackage) {
      if (createdSignaturePackage.task_id) {
        setTaskId(createdSignaturePackage.task_id);
      } else {
        setSignaturePackageFilesData([]);
        setZIndexOfWebViewer(1);
        setViewerIsLoading(false);
        setCreateEsignPackage(false);
        history.push(
          `/signature-wizard/${createdSignaturePackage.lawyawProjectId}/${createdSignaturePackage.id}/new`,
        );
      }
    }
  };

  useEffect(() => {
    if (reRouteToEsign) {
      createSignaturePackage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRouteToEsign]);
  useEffect(() => {
    setFullLoaded(false);
    if (
      isDoneLoading &&
      (hasCanaryDependancyError ||
        projectPopulateState?.hasError === true ||
        !project)
    ) {
      handleLoadProjectError();
    }
    if (isDoneLoading && project && canaryDependanciesMet) {
      setDocuments(project.documents);
      setIdentifier(project.documents[0].id);
      project.documents.forEach((document: any) => {
        addDocumentToSelectionSidebar(document);
      });
      setFullLoaded(true);
      setProjectid(projectId);
    }
    return () => {
      initialize();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoneLoading, canaryDependanciesMet]);

  /**
   * The document save process is a little convoluted here. We let the PDFViewer load the document using
   * webviewer, and wait for it to update currentPdfBlob once async loading is complete. Then we load the
   * next document (letting PDFViewer manage loading it), and repeat until we've processed all documents
   * in the set.
   */
  useEffect(() => {
    const uploadDocument = async () => {
      if (clioDocumentUploadURLs && currentPdfBlob && redirectUrl) {
        const documentId = currentPdfBlob.documentId;
        const uploadInfo = clioDocumentUploadURLs[documentId];
        if (!uploadInfo) {
          handleSaveInClioError();
          return;
        }

        let fileBlob;
        const docxObject = isHighlighted
          ? currentDocument?.docx
          : currentDocument?.processedDocx;
        if (currentDocument && docxObject) {
          fileBlob = await getFileFromUrl(
            docxObject,
            `${currentDocument.title}.docx`,
          );
        } else {
          fileBlob = currentPdfBlob.blobData;
        }

        clioService
          .uploadToS3(uploadInfo.latest_document_version, fileBlob)
          .then((response) => {
            if (response.status !== 200) {
              analyticsService.track('Save to Clio failed', {
                success: false,
                flow: clioParentId ? 'edit' : 'create',
                step: 'upload',
              });
              throw new Error('S3 upload was not successful.');
            }

            // since currentIndex of SidebarItemsStore is 1 - based instead of 0 - based
            // and project.documents array is 0 - based
            if (docIndex < project.documents.length) {
              setDocIndex(docIndex + 1);
            } else {
              markFullyUploaded();
              setClioDocumentUploadURLs(undefined);
            }
          })
          .catch(handleSaveInClioError);
      }
    };

    uploadDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPdfBlob, clioDocumentUploadURLs, redirectUrl]);

  if (!fullLoaded) {
    return (
      <PageLayout title={project?.name || 'Generating your Documents...'}>
        <LoadingOverlay
          title="Hang tight! We're getting your Documents ready"
          subtitle="We are generating your Documents now. This may take a few moments. We appreciate your patience."
          relative={true}
        />
      </PageLayout>
    );
  }

  const footerButtonsDisabled =
    !hasPdfTronLoadedDocument || savingInClio || viewerIsLoading;

  return (
    <PageLayout
      title={project?.name || 'Loading...'}
      showSelectionSidebar={true}
      selectionSidebarProps={{
        isDrafting: true,
        renderHeader: (items: any[]) => (
          <>
            <SelectionSidebarHeader title={'Documents'} count={items.length} />
            <AddDocumentButton projectId={project.id} />
          </>
        ),
      }}
      footer={
        project ? (
          <DraftingFooter
            onEdit={() => {
              analyticsService.track('Back Clicked', {
                page: 'drafting_page',
                location: 'footer',
              });
              handleEdit();
            }}
            onCurrentDownload={handleCurrentDownload}
            onAllDownload={handleAllDownload}
            onCreateEsign={handleCreateEsign}
            onSaveInClio={handleSaveInClio}
            showHighlights={isHighlighted}
            onToggleShowHighlights={handleToggleHighlight}
            isHighlightingEnabled={isHighlightingEnabled}
            disableDownload={footerButtonsDisabled}
            disableEdit={footerButtonsDisabled}
            disableEsign={footerButtonsDisabled}
            disableSaveInClio={footerButtonsDisabled}
          />
        ) : null
      }
      disableHorizontalPadding={true}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: '100%',
          marginTop: '-15px',
        }}
      >
        <div className={css(styles.draftingEditContainer)}>
          <PDFViewer
            viewerIsLoading={viewerIsLoading || savingInClio}
            backendLoaded={hasPdfTronLoadedDocument}
            setBackendLoaded={setHasPdfTronLoadedDocument}
            setViewerIsLoading={setViewerIsLoading}
            requestedDownloadPdf={requestedDownloadPdf}
            setRequestedDownloadPdf={setRequestedDownloadPdf}
            allDocuments={documents}
            docIndex={docIndex}
            setDocIndex={setDocIndex}
            currentDocument={currentDocument}
            requestedDownloadAllPdf={requestedDownloadAllPdf}
            currentProject={project}
            allPdfBlobs={allPdfBlobs}
            setAllPdfBlobs={setAllPdfBlobs}
            setRequestedDownloadAllPdf={setRequestedDownloadAllPdf}
            setReRouteToEsign={setReRouteToEsign}
            createEsignPackage={createEsignPackage}
            signaturePackageFilesData={signaturePackageFilesData}
            setSignaturePackageFilesData={setSignaturePackageFilesData}
            setCurrentDocumentPdfBlob={setCurrentPdfBlob}
            showHighlighting={isHighlighted}
          />
          {viewerIsLoading || savingInClio ? (
            <LoadingOverlay
              className={css(styles.draftingLoadingOverlay)}
              title={
                savingInClio
                  ? 'Saving your documents to Clio Manage...'
                  : 'Loading...'
              }
            />
          ) : null}
        </div>
      </div>
    </PageLayout>
  );
};

/* Libraries */
import React from 'react';
import { getFormattedDateFromIsoString } from '~/src/utils/date';
import styles from '~/src/entities/clioMatters/components/ManageMatter/styles';
import { css } from 'aphrodite';
import LaunchIcon from '@mui/icons-material/Launch';
import { ClioMatter } from '~/src/services/api/clioService';
import { clioManageMatterLink } from '~/src/utils/clio';

type ManageMatterDetailProps = {
  clioMatter: ClioMatter;
  showTitle?: boolean;
};

const ManageMatterDetail = ({
  clioMatter,
  showTitle,
}: ManageMatterDetailProps) => (
  <div analyticsname="Single Matter Sync - Manage Matter Detail">
    <div className={css(styles.section)}>
      {showTitle ? (
        <>
          <h3 className={css(styles.label)}>Matter</h3>
          <span>{clioMatter.display_number}</span>
          <br />
        </>
      ) : null}
      <a
        style={{ textDecoration: 'underline' }}
        href={clioManageMatterLink(+clioMatter.id)}
        target="_blank"
        rel="noreferrer"
      >
        <span
          analyticsname="Single Matter Sync - View in Clio Manage"
          className={css(styles.clioLink)}
        >
          View in Clio Manage
        </span>
        <LaunchIcon sx={{ fontSize: 16, color: '#2563EB' }} />
      </a>
    </div>

    <div className={css(styles.section)}>
      <h3 className={css(styles.label)}>Description</h3>
      <span>{clioMatter.description}</span>
    </div>

    <div className={css(styles.section)}>
      <h3 className={css(styles.label)}>Responsible attorney</h3>
      <span>{clioMatter.responsible_attorney?.name || 'N/A'}</span>
    </div>

    <div className={css(styles.section)}>
      <h3 className={css(styles.label)}>Open date</h3>
      <span> {getFormattedDateFromIsoString(clioMatter.open_date)}</span>
    </div>
  </div>
);
export default ManageMatterDetail;

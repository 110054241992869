import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DocumentIcon } from './ClipboardPaper.svg';
import Button from '~/src/components/Button';

export const EmptyTableMessage: React.FC<{
  onCreate: () => void;
}> = ({ onCreate }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        witdh: '100%',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <DocumentIcon />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          gap: '8px',
        }}
      >
        <Typography variant="subheading">
          Speed up client information gathering with questionnaires
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingBottom: '8px', maxWidth: '392px' }}
        >
          Create your first questionnaire template to get started — just select
          your documents and the questionnaire builder will automatically build
          a questionnaire for you.{' '}
          <a
            href="https://help.clio.com/hc/en-us/articles/24663592578075-Clio-Draft-Create-Manage-and-Share-Questionnaires"
            target="_blank"
            rel="noreferrer"
          >
            Learn how
          </a>
        </Typography>
        <Button primary onClick={onCreate}>
          Create questionnaire template
        </Button>
      </Box>
    </Box>
  );
};

import { Box, IconButton, SvgIcon } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React from 'react';
import {
  CardListItemWithActions,
  CardListItemMetaText,
} from '~/src/components/CardList';
import { Eye, RefreshCcw, Trash, Type } from 'react-feather';
import { getFormattedDateFromIsoString } from '~/src/utils/date';
import { ReactComponent as ClioIcon } from '~/src/static/clio-icon.svg';
import { useCanSyncSingleMatter } from '~/src/hooks/useClio';

export const MattersCardListTable = ({
  onView,
  onRename,
  onDelete,
  onSync,
  matters,
}) => {
  const canSyncSingleMatter = useCanSyncSingleMatter();
  return matters.map((matter) => {
    const syncAction =
      matter.clioMatterId && canSyncSingleMatter
        ? [
            {
              label: 'Get Updates from Clio',
              onClick: () => onSync(matter),
              icon: <RefreshCcw size={16} />,
            },
          ]
        : [];

    return (
      <Box pb={1} key={matter.id}>
        <CardListItemWithActions
          key={matter.id}
          onClick={() => onView(matter)}
          actions={{
            actions: [
              {
                label: 'View',
                onClick: () => onView(matter),
                icon: <Eye size={16} />,
              },
              {
                label: 'Rename',
                icon: <Type size={16} />,
                onClick: () => onRename(matter),
              },
              {
                label: 'Delete',
                icon: <Trash size={16} />,
                onClick: () => onDelete(matter),
              },
              ...syncAction,
            ],
          }}
          title={{
            label: (
              <>
                {matter.title}{' '}
                {matter.clioMatterId ? <SvgIcon component={ClioIcon} /> : null}
              </>
            ),
            meta: [
              <CardListItemMetaText key={'documents'}>
                {matter.numberOfDocuments} documents
              </CardListItemMetaText>,
            ],
          }}
          meta={
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <CardListItemMetaText>
                Last updated:{' '}
                {getFormattedDateFromIsoString(matter.lastModified)}
              </CardListItemMetaText>
              <IconButton sx={{ fontSize: 16 }}>
                <MoreVert fontSize={'inherit'} />
              </IconButton>
            </Box>
          }
        />
      </Box>
    );
  });
};

import React, { useEffect } from 'react';
import * as clipboard from 'clipboardy';

import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '~/src/contexts/Layout';
import { Form, renderFormField } from '~/src/components/Forms';
import analyticsService from '~/src/services/analytics';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useClioQuestionnaireSubmissionById } from '~/src/entities/questionnaires/hooks/useClioQuestionnaireSubmissionById';
import { usePersistRelatedQuestionnaireSubmission } from '~/src/entities/questionnaires/hooks';

import { tryConvertToNumber } from '../../utils';
import { MessageDialog } from '~/src/components/Modal/mui';
import { Button } from '@mui/material';

export const ShareLink: React.FC<{
  questionnaireId: string;
  matterId: string;
  submissionId: string;
  submissionTitle: string;
  page: 'questionnaire_builder_page' | 'questionnaire_list_page';
  questionnaireLink: string;
  onClose: () => void;
}> = ({
  questionnaireId,
  matterId,
  submissionId,
  submissionTitle,
  page,
  questionnaireLink,
  onClose,
}) => {
  const { showToast } = useLayoutContext();

  const orgFprint = useCurrentOrgFprint();

  const { data: questionnaireSubmission } = useClioQuestionnaireSubmissionById(
    orgFprint,
    submissionId!,
    {
      enabled: !!submissionId,
    },
  );

  const persistRelatedQuestionnaireSubmission =
    usePersistRelatedQuestionnaireSubmission(orgFprint);

  const handleCopyLink = () => {
    analyticsService.track('Copied Share Link', {
      questionnaire_Id: tryConvertToNumber(questionnaireId),
      matter_id: tryConvertToNumber(matterId),
      page,
    });
    clipboard.write(questionnaireLink).then(() => {
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Link copied to clipboard!',
      });
    });
  };

  const fields = [
    {
      label: 'Questionnaire link',
      id: 'link',
      type: 'copyLink',
      target: '_blank',
      defaultValue: questionnaireLink,
      onClick: handleCopyLink,
    },
  ];

  const fieldsProp = fields.map((field) => {
    return renderFormField(field);
  });

  useEffect(() => {
    if (questionnaireSubmission) {
      // save to clientmatter_relatedquestionnairesubmission table in clio-draft-backend database
      const payload = {
        questionnaire_submission_id: questionnaireSubmission.id,
        questionnaire_id: questionnaireSubmission.questionnaire_id,
        questionnaire_version_id:
          questionnaireSubmission.questionnaire_version.id,
        title: submissionTitle,
        created_at: questionnaireSubmission.created_at,
        project_id: null,
        matter_id: matterId,
        data: {
          questions: [],
        },
      };

      persistRelatedQuestionnaireSubmission.mutate(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // triggered only once

  return (
    <MessageDialog
      title={'Link generated'}
      onClose={onClose}
      dialogActions={<Button onClick={onClose}>Done</Button>}
    >
      Share this link with the recipient to gather their responses.
      <br />
      If you need to access the link later, you&apos;ll find it in the Pending
      section.
      <br />
      <br />
      <Form
        fields={fieldsProp}
        className={undefined}
        focusFirstInput={undefined}
        focusTimeout={undefined}
      />
    </MessageDialog>
  );
};

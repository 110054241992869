import React from 'react';

import Button from '~/src/components/Button';
import { useUpdatePaymentMethodModal } from '~/src/entities/subscription';
import { useStore } from '~/src/hooks/useMst';

import styles from './styles';
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '~/src/contexts';
import cbsSubscriptionsService from '~/src/services/api/cbsSubscriptionsService';

export const UpdatePaymentMethodButton = () => {
  const store = useStore();
  const [toggleModal, updatePaymentMethodModal] = useUpdatePaymentMethodModal({
    onUpdated: () => store.user.currentOrganization?.fetchSubscription(),
  });
  const { isCbsIntegrationEnabled } = useFeatureAccesses();
  const { showToast } = useLayoutContext();

  const handleClick = async () => {
    if (isCbsIntegrationEnabled) {
      try {
        const orgFprint = store.user.currentOrganization?.fprint;

        if (orgFprint) {
          const response =
            await cbsSubscriptionsService.getPaymentMethodUpdateUrl(orgFprint);

          window.open(response.customer_payment_methods_url, '_blank');
        }
      } catch (error) {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Unable to update payment method, please try again later.',
        });
      }
    } else {
      toggleModal();
    }
  };

  return (
    <>
      {updatePaymentMethodModal}
      <Button
        cssStyle={styles.settingsSubscriptionLabelButton}
        onClick={handleClick}
      >
        Update payment method
      </Button>
    </>
  );
};

/* Libs */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/* Containers */

/* Hooks */
import useContentPackageWizardRedirect from '~/src/hooks/useContentPackageWizardRedirect';
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';

/* Routes */
import TemplateRoutes from './Templates';
import QuestionnaireRoutes from './Questionnaires';
import FormTemplateDrafting from './FormTemplateDrafting';
import AddDocumentRoutes from './AddDocument';
import SignatureRoutesContainer from './Signatures';
import { RequiresSubscription } from '~/src/containers/RequiresSubscription';
import Page from '~/src/components/PageLayout/Page';
import { SubscribePage } from '~/src/pages/Subscribe';
import { Subscribe as OldSubscribePage } from '~/src/pages/SubscribeOld';
import { Subscribe as CbsSubscribePage } from '~/src/pages/SubscribeCbs';
import { SettingsPage } from '~/src/pages/Settings';
import { ClioConnect } from '~/src/pages/ClioConnect';
import { ContactsPage } from '~/src/pages/Contacts';
import { MattersPage } from '~/src/pages/Matters';
import { LibraryPage } from '~/src/pages/Library';
import { DocumentsPage } from '~/src/pages/Documents';
import { DocumentSetsPage } from '~/src/pages/DocumentSets';
import { MatterDetailsPage } from '~/src/pages/MatterDetails';
import { SignaturesPage } from '~/src/pages/Signatures';
import { SignatureDetailPage } from '~/src/pages/SignaturePackage';
import { PopulatePage } from '~/src/pages/Populate';
import { ViewDocumentPage } from '~/src/pages/ViewDocument';
import { DraftingPage } from '~/src/pages/Drafting';
import ClioQuestionnaireRoutes from './ClioQuestionnaires';
import { WelcomeFormPage } from '~/src/pages/Welcome';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { ContentPackageWizard } from '~/src/pages/SubscribeOld/ContentPackageWizard';

const Routes = () => {
  const { isQuestionnairesEnabled, isClioQuestionnaireEnabled } =
    useFeatureAccesses();

  const isNewSubscribePageEnabled = useUIFlagEnabled('newSubscribePage');
  const { isCbsIntegrationEnabled } = useFeatureAccesses();

  const shouldRedirect = useContentPackageWizardRedirect();

  const redirectComponent = isCbsIntegrationEnabled
    ? CbsSubscribePage
    : isNewSubscribePageEnabled
    ? SubscribePage
    : OldSubscribePage;

  return (
    <Switch>
      {shouldRedirect && <Redirect to="/content-package-wizard" />}
      <Route
        exact
        path={['/welcome', '/welcome-free-trial']}
        component={WelcomeFormPage}
      />
      <Route exact path={'/subscribe'} component={redirectComponent} />

      <Route path="/content-package-wizard">
        <ContentPackageWizard />
      </Route>

      {/* private routes */}
      <RequiresSubscription>
        <Switch>
          <Route
            path="/clio/connect"
            render={({ location }) => {
              const params = new URLSearchParams(location.search);
              const code = params.get('code');
              return code ? <ClioConnect code={code} /> : <Redirect to={'/'} />;
            }}
          />

          <Route exact path="/matter">
            <MattersPage />
          </Route>

          <Route
            exact
            path="/matter/:matterId"
            render={({ location, match }) => (
              <MatterDetailsPage
                matterId={match.params.matterId}
                matterTitle={location.state?.title}
              />
            )}
          />

          <Route path="/library">
            <LibraryPage />
          </Route>
          <Redirect exact from="/templates" to="/library/templates" />
          <Route path="/templates">
            <Page>
              <TemplateRoutes />
            </Page>
          </Route>
          <Route
            exact
            path="/drafting/:projectId"
            render={({ match: { params } }) => {
              const { projectId } = params;
              return <DraftingPage projectId={parseInt(projectId)} />;
            }}
          />
          <Route path="/form-template">
            <Page showHeader={false} disablePadding={true}>
              <FormTemplateDrafting />
            </Page>
          </Route>

          <Route
            exact
            path="/populate/:projectId"
            render={(props) => {
              const { projectId } = props.match.params;
              let initialProject = false;
              if (props.location && props.location.state) {
                initialProject = props.location.state.initialProject;
              }
              return (
                <PopulatePage
                  projectId={parseInt(projectId)}
                  initialProject={initialProject}
                />
              );
            }}
          />

          <Route
            exact
            path="/populate/:projectId/document/:documentId"
            render={({ match }) => (
              <ViewDocumentPage
                projectId={match.params.projectId}
                documentId={match.params.documentId}
              />
            )}
          />

          <Route exact path="/contacts">
            <ContactsPage />
          </Route>

          <Route exact path="/documents">
            <DocumentsPage />
          </Route>

          <Route exact path="/document-sets">
            <DocumentSetsPage />
          </Route>

          <Route exact path="/signatures">
            <SignaturesPage />
          </Route>

          <Route
            exact
            path="/signatures/details/:packageId/"
            render={({ match: { params } }) => (
              <SignatureDetailPage signaturePackageId={params.packageId} />
            )}
          />

          <Route path="/signatures/prox">
            <Page
              disablePadding={true}
              showNavigation={false}
              showHeader={false}
            >
              <SignatureRoutesContainer />
            </Page>
          </Route>
          <Route path="/signatures">
            <Page>
              <SignatureRoutesContainer />
            </Page>
          </Route>
          <Route path="/signature-wizard">
            <Page
              disablePadding={true}
              showNavigation={false}
              showHeader={false}
            >
              <SignatureRoutesContainer />
            </Page>
          </Route>
          <Route path="/custom-signature-package">
            <Page
              disablePadding={true}
              showNavigation={false}
              showHeader={false}
            >
              <SignatureRoutesContainer />
            </Page>
          </Route>

          <Route path="/add-document">
            <AddDocumentRoutes />
          </Route>

          <Route path="/settings">
            <SettingsPage />
          </Route>
          {isQuestionnairesEnabled && (
            <Route path="/questionnaire">
              <Page>
                <QuestionnaireRoutes />
              </Page>
            </Route>
          )}

          {isClioQuestionnaireEnabled && (
            <Route path="/questionnaires">
              <ClioQuestionnaireRoutes />
            </Route>
          )}

          <Redirect to="/library" />
        </Switch>
      </RequiresSubscription>
    </Switch>
  );
};

export default Routes;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';

import dropdownStyles from '~/src/components/Dropdown/styles';
import { STATUS_ICON_MAP } from '~/src/pages/Signatures/Signatures/constants';
import styles from './styles';
import { getFormattedDateFromIsoString } from '~/src/utils/date';

// TODO - Add appropriate column titles and action pages to the signatures table,
// fix bug "drag" box shows up before finished loading

const SignatureActions = () => {
  return <div />;
};

const RowAction = ({ actions, signaturePackage }) => {
  const renderTrigger = ({ toggleDropdown }) => (
    <span analyticsname="Actions">
      <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
        Actions <Triangle primary orientation={'down'} />
      </Button>
    </span>
  );

  const handleRename = () => {
    actions.rename(signaturePackage);
  };

  // const handleEdit = () => {
  //   actions.edit(signaturePackage);
  // };

  const handleResume = () => {
    actions.resume(signaturePackage);
  };

  const handleView = () => {
    actions.view(signaturePackage);
  };

  const handleCancel = () => {
    actions.cancel(signaturePackage);
  };

  const handleDelete = () => {
    actions.delete(signaturePackage);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      {signaturePackage.packageStatus !== 'Unknown' &&
        signaturePackage.packageStatus !== 'Draft' && (
          <a
            onClick={handleView}
            className={css(dropdownStyles.dropdownActionItem)}
            analyticsname="View"
          >
            View
          </a>
        )}

      {(signaturePackage.packageStatus === 'Unknown' ||
        signaturePackage.packageStatus === 'Draft') && (
        <>
          {/* <a
              onClick={handleEdit}
              className={css(dropdownStyles.dropdownActionItem)}
              analyticsname="Edit"
            >
              Edit
            </a> */}

          <a
            onClick={handleResume}
            className={css(dropdownStyles.dropdownActionItem)}
            analyticsname="Resume"
          >
            Edit
          </a>
        </>
      )}

      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>

      {signaturePackage.packageStatus === 'Pending' && (
        <a
          onClick={handleCancel}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Cancel Signature Request"
        >
          Cancel signature request
        </a>
      )}

      {signaturePackage.packageStatus !== 'Pending' && (
        <a
          onClick={handleDelete}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Delete"
        >
          Delete
        </a>
      )}
    </Dropdown>
  );
};

const Signatures = ({
  hideHeader,
  noBorder,
  visibleItems,
  signaturePackages,
  rowActions,
  isLoading,
  onSearchChange,
  onSortByChange,
}) => {
  const renderRowStatus = (signaturePackage) => {
    const Icon = STATUS_ICON_MAP[signaturePackage.packageStatus];
    return (
      <div className="flex">
        <div className="mr-2">{Icon && <Icon />}</div>
        <div>{signaturePackage.packageStatus}</div>
      </div>
    );
  };

  const renderRowAction = (signaturePackage) => (
    <RowAction actions={rowActions} signaturePackage={signaturePackage} />
  );

  const newSignatureButton = (
    <Button
      cssStyle={styles.newSignatureButton}
      onClick={() => rowActions.new()}
      primary
      disabled={false}
    >
      New signature
    </Button>
  );

  return (
    <>
      <div analyticsname="Signature Actions">
        <Table
          onSearchChange={onSearchChange}
          onSortByChange={onSortByChange}
          isLoading={isLoading}
          noBorder={noBorder}
          hideHeader={hideHeader}
          emptyTitle={'No signature packages match that filter'}
          searchPlaceholder="Search signatures"
          renderActions={() => <SignatureActions />}
          data={signaturePackages}
          visibleItems={visibleItems}
          customButton={newSignatureButton}
          columns={[
            {
              label: 'Package title',
              render: (data) => {
                return data.title
                  ? data.title
                  : `Untitled signature request (# ${data.id})`;
              },
              style: {
                width: '40%',
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
            {
              label: 'Created at',
              dataKey: 'createdAt',
              render: getFormattedDateFromIsoString,
              style: { width: '10%', textAlign: 'left' },
            },
            {
              label: 'Status',
              render: renderRowStatus,
              style: { width: '20%', textAlign: 'left' },
            },
            {
              label: 'Actions',
              hideLabel: true,
              render: renderRowAction,
              style: {
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
          ]}
        />
      </div>
    </>
  );
};

Signatures.propTypes = {
  hideHeader: PropTypes.bool,
  noBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  signaturePackages: PropTypes.array,
  visibleItems: PropTypes.number,
  rowActions: PropTypes.shape({
    view: PropTypes.func,
    edit: PropTypes.func,
    cancel: PropTypes.func,
  }),
};

Signatures.defaultProps = {
  signaturePackages: [],
  visibleItems: 10,
  rowActions: {
    delete: () => {},
  },
  hideHeader: false,
  noBorder: false,
  isLoading: true,
};

export default Signatures;

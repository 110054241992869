import { Box, Tooltip, styled } from '@mui/material';
import React, { useState } from 'react';
import { CheckCircle } from 'react-feather';

const StyledCheckCircleIcon = styled(CheckCircle)();

type ToggleProgressTrackerProps = {
  isProgressTrackerHidden: boolean;
  onProgressTrackerClick: () => void;
};

const ToggleProgressTracker = ({
  isProgressTrackerHidden,
  onProgressTrackerClick,
}: ToggleProgressTrackerProps) => {
  const [isMouseOver, setMouseOver] = useState(false);
  return (
    <Tooltip
      title={
        isProgressTrackerHidden ? 'Show Review Widget' : 'Hide Review Widget'
      }
      placement="bottom"
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        <Box
          sx={(theme) => {
            let color = theme.palette.blue[600];
            let backgroundColor = theme.palette.blue[50];
            if (isProgressTrackerHidden) {
              color = theme.palette.grey[900];
              backgroundColor = theme.palette.background.paper!;
            }
            if (isMouseOver) {
              color = theme.palette.grey[900];
              backgroundColor = theme.palette.background.default;
            }
            return {
              width: '32px',
              height: '32px',
              color,
              backgroundColor,
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            };
          }}
        >
          <StyledCheckCircleIcon onClick={onProgressTrackerClick} size={24} />
        </Box>
      </Box>
    </Tooltip>
  );
};

export default ToggleProgressTracker;

import React from 'react';

type SummaryFormItemProps = {
  label: React.ReactNode;
  price: React.ReactNode;
  hint?: string;
};

export const SummaryFormItem = ({
  label,
  price,
  hint,
}: SummaryFormItemProps) => {
  return (
    <div className="mb-3">
      <div className="flex flex-row justify-between text-xs">
        <span>{label}</span>
        <span className="whitespace-nowrap">{price}</span>
      </div>
      {hint && <div className="text-xs text-gray-500">{hint}</div>}
    </div>
  );
};

import { APIError } from '~/src/utils/error';
import { queryClient } from '~/src/utils/queryClient';
import { Subscription, SubscriptionV2, CbsSubscription } from '../types';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { keys } from '../keys';
import { getSubscriptionUrl } from '../urls';
import { useQuery, UseQueryOptions } from 'react-query';
import { useCurrentOrgFprint } from '../../user';

const queryKey = keys.detail();

type SubscriptionAPIResponse = Subscription | SubscriptionV2 | CbsSubscription;

const queryFn = async (orgFprint: string) =>
  fetchAndParse<SubscriptionAPIResponse>(getSubscriptionUrl(orgFprint));

export const useSubscription = (
  options: UseQueryOptions<SubscriptionAPIResponse, APIError> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey,
      queryFn: () => queryFn(orgFprint),
      refetchOnWindowFocus: false,
    }),
  );
};

export const fetchSubscription = async (
  orgFprint: string,
  client = queryClient,
) => {
  const response = await queryFn(orgFprint);
  client.setQueryData(queryKey, response);
  return response;
};

export const invalidateSubscription = (client = queryClient) =>
  client.invalidateQueries(queryKey);

import { type Document } from '@clio/questionnaire-builder';

// given a list of documents, return a list of untagged fields matching a pattern
export const untaggedFields = (documents: Document[]) => {
  return (
    documents?.flatMap(
      (doc) =>
        doc.pdf_field_elements
          ?.filter((e) => /^untagged_\d+$/.test(e.tag))
          .map((e) => e.tag) || [],
    ) || []
  );
};

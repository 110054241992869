import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import theme from '~/src/theme';
import Card from '../Card';
import Checkbox from '../Checkbox';

export type Plan = {
  name: string;
  id: string;
};

type ConfigureCbsContentPackagesModalProps = {
  handleCancel: () => void;
  handleChange: (selected: boolean[]) => void;
  handleUpdate: (selected: boolean[]) => boolean[];
  open: boolean;
  defaultValue: boolean[];
  plans: Plan[] | ((selected: boolean[]) => Plan[]);
  orgFprint: string;
};

const ConfigureCbsContentPackagesModal = ({
  open,
  plans: propPlans,
  defaultValue,
  handleCancel,
  handleChange,
}: ConfigureCbsContentPackagesModalProps) => {
  const [selected, setSelected] = useState(defaultValue);

  const plansToRender =
    typeof propPlans === 'function' ? propPlans(selected) : propPlans;

  const title = 'Choose libraries';

  const onConfirm = () => {
    handleChange(selected);
  };

  function toggleCheck(key: number) {
    setSelected((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[key] = !newSelected[key];
      return newSelected;
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      scroll={'paper'}
      PaperProps={{
        sx: {
          width: '100%',
          maxHeight: '560px!important',
          minWidth: '560px!important',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        {plansToRender &&
          plansToRender.map(({ name, ...checkboxProps }, key) => (
            <div className="w-full mb-2" key={key}>
              <Card dynamic>
                <div className="flex flex-row items-start">
                  <Checkbox
                    {...checkboxProps}
                    id={key}
                    disabled={false}
                    checked={selected[key]}
                    color={theme.colors.darkBlue}
                    size={3}
                    onClick={() => toggleCheck(key)}
                  />
                  <div className="flex flex-row items-center justify-between w-full ml-2">
                    <div className="flex flex-col">
                      <div className="font-medium text-sm mb-1">{name}</div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={onConfirm} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureCbsContentPackagesModal;

import React from 'react';
import { Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { FieldRenderProps } from '../PopulateCardField';
import {
  ProjectStackField,
  ProjectStackDocument,
  SUPPORTED_DATE_FORMATS,
} from '~/src/entities/project';
import FieldHeader from '../FieldHeader';
import { getLocalDateFormat } from '~/src/utils/date';

export type DatePickerFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  projectStackDocuments: ProjectStackDocument[];
};

export const DatePickerField = React.memo(
  ({ field, projectField, projectStackDocuments }: DatePickerFieldProps) => {
    const { value, onChange, countTouched, ref, ...rest } = field;

    const format = projectField.date_format || getLocalDateFormat();

    const parseDateStringToMoment = (dateString: string) => {
      if (!dateString) return null;

      // First try parsing with the specified format
      const momentWithFormat = moment.utc(dateString, format, true);
      if (momentWithFormat.isValid()) {
        const formattedDate = momentWithFormat.format(format);
        onChange(formattedDate);
        return momentWithFormat;
      }

      // Note: AJR Mar 13 2025
      // Moment.js can not parse certain string date formats as it doesn't have enough information to accurately parse it.
      // For example "04/02/25" is ambiguous and can be interpreted as April 2, 2025 or February 4, 2025 etc....
      // As a temporary solution, we try to parse the date string with a list of common date formats that are pulled from the add-in and we use the first format that is valid.
      // This can still lead to issues if we match the wrong format.
      // This list of common formats is pulled from the add-in https://github.com/clio/clio-draft-docx/blob/master/src/components/DateFormatSelect/index.js#L7
      // The proper solution here is to save the actual date timestamp as a value for the populate page instead of it's formatted version.
      for (const commonFormat of SUPPORTED_DATE_FORMATS) {
        const parsedDate = moment.utc(dateString, commonFormat, true);
        if (parsedDate.isValid()) {
          const formattedDate = parsedDate.format(format);
          onChange(formattedDate);
          return parsedDate;
        }
      }

      // Last resort: try parsing with moment's default parsing
      const parsedDate = moment.utc(dateString);
      if (parsedDate.isValid()) {
        const formattedDate = parsedDate.format(format);
        onChange(formattedDate);
        return parsedDate;
      }

      return null;
    };

    const handleChange = (momentObj: moment.Moment | null) => {
      if (momentObj && momentObj.isValid()) {
        onChange(momentObj.format(format));
      } else {
        onChange('');
      }
    };

    return (
      <Box>
        <Box>
          <FieldHeader
            projectField={projectField}
            projectStackDocuments={projectStackDocuments}
          />
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  placeholder: format,
                },
                field: { shouldRespectLeadingZeros: true },
              }}
              inputRef={ref}
              format={format}
              value={parseDateStringToMoment(value)}
              onChange={(evt) => {
                handleChange(evt);
                if (countTouched) {
                  countTouched(field.name);
                }
              }}
              {...rest}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.field.value === nextProps.field.value &&
      prevProps.projectField === nextProps.projectField &&
      prevProps.projectStackDocuments === nextProps.projectStackDocuments
    );
  },
);

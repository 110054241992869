import { Box, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Clipboard } from 'react-feather';

type ToggleUnmappedQuestionsProps = {
  isUnmappedQuestionsHidden: boolean;
  onUnmappedQuestionsClick: () => void;
};

const ToggleUnmappedQuestions = ({
  isUnmappedQuestionsHidden,
  onUnmappedQuestionsClick,
}: ToggleUnmappedQuestionsProps) => {
  const [isMouseOver, setMouseOver] = useState(false);
  return (
    <Tooltip
      title={
        isUnmappedQuestionsHidden
          ? 'Show Live Questionnaire'
          : 'Hide Live Questionnaire'
      }
      placement="bottom"
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
        sx={{ marginLeft: '7px' }}
      >
        <Box
          sx={(theme) => {
            let color = theme.palette.blue[600];
            let backgroundColor = theme.palette.blue[50];
            if (isUnmappedQuestionsHidden) {
              color = theme.palette.grey[900];
              backgroundColor = theme.palette.background.paper!;
            }
            if (isMouseOver) {
              color = theme.palette.grey[900];
              backgroundColor = theme.palette.background.default;
            }
            return {
              width: '32px',
              height: '32px',
              color,
              backgroundColor,
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            };
          }}
        >
          <Clipboard onClick={onUnmappedQuestionsClick} size={24} />
        </Box>
      </Box>
    </Tooltip>
  );
};

export default ToggleUnmappedQuestions;

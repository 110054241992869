import React from 'react';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'react-feather';
import moment from 'moment';

import Banner from '~/src/components/Banner';
import {
  isSubscriptionCanceled,
  useSubscription,
} from '~/src/entities/subscription';
import { getFormattedFullDateFromIsoString } from '~/src/utils/date';

const SubscriptionCancelationBannerContainer = () => {
  const { data } = useSubscription();
  if (!data) return null;

  if (!isSubscriptionCanceled(data)) return null;

  const endDate = getFormattedFullDateFromIsoString(data.current_period_end);

  const description = moment().isAfter(data.current_period_end)
    ? `Your subscription was canceled on ${endDate}`
    : `Your subscription is set to cancel on ${endDate}`;

  return (
    <Banner small alert fullWidth Icon={AlertCircle}>
      {description}. Click{' '}
      <Link to="/settings/subscriptions">&nbsp;here&nbsp;</Link> to renew your
      subscription.
    </Banner>
  );
};

export default SubscriptionCancelationBannerContainer;

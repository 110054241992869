/* Hooks */
import useMst from '~/src/hooks/useMst';
import { useObserver } from 'mobx-react';

/* Components */

/* Types */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { TemplateDefaultValues } from '~/src/models';
import { useLayoutContext } from '../contexts/Layout';
import { history } from '../utils/history';
import { VALIDATION_TYPES } from '~/src/utils/validation';

const useTemplateDefaultValuesListActions = () => {
  const {
    sidebarItemTotalNumber,
    onSubmit,
    checkTemplateDefaultValuesExistInTemplateSets,
    removeRelatedTemplateDefaultValuesFromSet,
    clearError,
  } = useMst((store) => ({
    sidebarItemTotalNumber: store.sidebarItems.items.length,
    onSubmit: store.templateDefaultValues.submitTemplateDefaultValues,
    checkTemplateDefaultValuesExistInTemplateSets:
      store.templateDefaultValues.checkTemplateDefaultValuesExistInTemplateSets,
    clearError: store.templateDefaultValues.clearError,
    removeRelatedTemplateDefaultValuesFromSet:
      store.templateSets.removeRelatedTemplateDefaultValuesFromSet,
  }));
  const { showModal, hideModal, showToast } = useLayoutContext();

  useObserver(() => ({
    sidebarItemTotalNumber,
  }));

  const closeModal = () => {
    clearError();
    hideModal();
  };

  const handleRenameTemplateDefaultValues = (
    templateDefaultValues: TemplateDefaultValues,
  ) => {
    showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
      title: 'Rename template',
      inputValue: templateDefaultValues.title,
      enableTextInput: true,
      validation: VALIDATION_TYPES.templateName,
      onCancel: () => {
        closeModal();
      },
      onSave: async (title: string) => {
        const success = await onSubmit('update', {
          id: templateDefaultValues?.id,
          title,
        });
        if (success) {
          hideModal();
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Renamed template successfully',
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        }
      },
      confirmButtonName: 'Rename',
    });
  };

  const handleDeleteTemplateDefaultValues = async (
    templateDefaultValues: TemplateDefaultValues,
  ) => {
    const relatedTemplateSetsCount =
      await checkTemplateDefaultValuesExistInTemplateSets(
        templateDefaultValues.id,
      );
    const templateSetCountSubStatement = (count: number) => {
      return count === 1
        ? 'one template set'
        : `${relatedTemplateSetsCount} template sets`;
    };
    showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
      title: 'Delete template',
      message:
        relatedTemplateSetsCount > 0
          ? `This form template is used in ${templateSetCountSubStatement(
              relatedTemplateSetsCount,
            )}. Deleting this Form Template will remove it from those template sets`
          : 'Are you sure you want to permanently delete this form template? This action cannot be undone.',
      onCancel: () => {
        closeModal();
      },
      onDelete: async () => {
        await removeRelatedTemplateDefaultValuesFromSet(
          templateDefaultValues?.id,
        );
        const success = await onSubmit('delete', {
          id: templateDefaultValues?.id,
        });
        if (success) {
          hideModal();
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Deleted template successfully',
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        }
      },
    });
  };

  const handleDuplicateTemplateDefaultValues = (
    templateDefaultValues: TemplateDefaultValues,
  ) => {
    showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
      title: 'Duplicate template',
      inputValue: templateDefaultValues.title,
      enableTextInput: true,
      onCancel: () => {
        closeModal();
      },
      onSave: async (title: string) => {
        const { success } = await onSubmit('duplicate', {
          id: templateDefaultValues?.id,
          title,
        });
        if (success) {
          hideModal();
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Duplicated template successfully',
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        }
      },
      confirmButtonName: 'Duplicate',
    });
  };

  const handleEditTemplateDefaultValues = (template: TemplateDefaultValues) => {
    history.push(`/form-template/${template.id}`);
  };

  return {
    rename: handleRenameTemplateDefaultValues,
    delete: handleDeleteTemplateDefaultValues,
    duplicate: handleDuplicateTemplateDefaultValues,
    edit: handleEditTemplateDefaultValues,
  };
};

export default useTemplateDefaultValuesListActions;

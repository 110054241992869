import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';

import Card, { CardTitle } from '~/src/components/Card';

import styles from '../styles';
import { SubscriptionDetails } from './SubscriptionDetails';

const SettingsSubscription = (props) => {
  const { subscription } = props;

  const [pendingRequest, setPendingRequest] = useState(false);

  useEffect(() => {
    setPendingRequest(false);
  }, []);

  return (
    <div>
      {subscription ? (
        <SubscriptionDetails pendingRequest={pendingRequest} />
      ) : (
        <Card elevate className={css(styles.settingsCardContainer)}>
          <>
            You have no active subscription. To enquire about your subscription,
            please contact Clio support.
          </>
        </Card>
      )}
      <div className={css(styles.settingsOuterButtonContainer)}>
        {subscription && !subscription.canceledAt ? (
          <Card elevate>
            <CardTitle bold>Modify subscription</CardTitle>
            To make changes to your subscription, please contact Clio support.
          </Card>
        ) : null}
      </div>
    </div>
  );
};

export const SettingsCbsSubscription = SettingsSubscription;

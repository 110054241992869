/* Libraries */
import { types, flow } from 'mobx-state-tree';
import { startCase, sortBy } from 'lodash';

/* Models */
import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';

/* API Services */
import contactsService from '~/src/services/contacts';

/* Utils */
import { getContactIdentifier } from '~/src/utils/dataTransformers';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { getOrgFprintFromStoreNode } from './utils';
import analyticsService from '../services/analytics';
import {
  CONTACT_EDIT_FORM_EXCLUDED_FIELDS,
  ORGANIZATION_CONTACT_SPECIFIC_FIELDS,
  PERSON_CONTACT_SPECIFIC_FIELDS,
} from '~/src/utils/constants';

export const PERSON_TYPE = 'Person';
export const ORGANIZATION_TYPE = 'Organization';
export const CARD_TYPE_CONTACT = 'Contact';

/**
 * This function is a helper which takes a field and returns the field it should map  to
 * So far this function is only used to normalize (flatten) email and emailAddress
 * @param {string} field - Key we wish to normalize
 * @returns {string} - The normalized field
 */
const _getNormalizedFieldKey = (field) => {
  if (field === 'email' || field === 'emailAddress') {
    return 'email';
  }
  return field;
};

/**
 * This function is a helper that is a sister function to _getNormalizedFieldKey. It is used to make sure that we 'hoist' up the correct
 * value. I.e. if the values array has a value for emailAddress make sure that when we show the normalized email field we show a value.
 * @param {object} values - Object with properties that represent the fields
 * @param {string} field - Key for the field we wish to get the value for
 * @returns - The value to show for the associated field
 */
const _getNormalizedFieldValue = (values, field) => {
  let value = '';
  if (field === 'email' || field === 'emailAddress') {
    value = values.email ? values.email : values.emailAddress;
  } else {
    value = values[field];
  }
  return value;
};

const ContactModel = types
  .model({
    id: types.integer,
    identifier: types.identifier,
    role: types.maybeNull(types.string),
    type: types.string,
    fields: types.frozen(),
  })
  .actions((self) => {
    const update = flow(function* update(properties) {
      const nextContact = {
        id: self.id,
        type: self.type,
        fields: properties,
      };

      yield contactsService.updateContact(
        getOrgFprintFromStoreNode(self),
        nextContact,
      );

      self.fields = properties;
    });

    return {
      update,
    };
  })
  .views((self) => ({
    fullName: () => {
      const firstName = self.fields && self.fields.firstName;
      const lastName = self.fields && self.fields.lastName;
      const email = self.fields && self.fields.emailAddress;
      const companyName = self.fields && self.fields.companyName;

      if (companyName) {
        return companyName;
      }

      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }

      if (!firstName && lastName) {
        return `${lastName}`;
      }

      if (email) {
        return email;
      }

      return 'Contact';
    },
    getFormFields: async (includeType, formValues = {}) => {
      const defaultFieldKeys = await contactsService.fetchDefaultContactFields(
        getOrgFprintFromStoreNode(self),
        self.id,
      );
      const allFieldKeys = [...defaultFieldKeys, ...Object.keys(formValues)];
      // Removes duplicate field keys as formValues keys could overwrite  defaultFieldKeys
      const uniqueFieldKeys = allFieldKeys.filter(
        (item, index) =>
          allFieldKeys.indexOf(_getNormalizedFieldKey(item)) === index,
      );

      const fields = [];

      uniqueFieldKeys.forEach((fieldKey) => {
        let isFieldBelongsToType = true; // To determine whether the field belongs to selected contact type
        const isEmailField = fieldKey.toLowerCase().endsWith('email');
        const defaultValue =
          _getNormalizedFieldValue(formValues, fieldKey) ||
          _getNormalizedFieldValue(self.fields, fieldKey);
        if (self.type === ORGANIZATION_TYPE) {
          if (
            PERSON_CONTACT_SPECIFIC_FIELDS.includes(fieldKey) &&
            !ORGANIZATION_CONTACT_SPECIFIC_FIELDS.includes(fieldKey)
          ) {
            isFieldBelongsToType = false;
          }
        }

        if (CONTACT_EDIT_FORM_EXCLUDED_FIELDS.includes(fieldKey)) {
          isFieldBelongsToType = false;
        }
        if (isFieldBelongsToType) {
          fields.push({
            label:
              self.type === ORGANIZATION_TYPE && fieldKey === 'lastName'
                ? 'Name' //As of now the Organization name is mapped into the lastName field in the backend.
                : startCase(fieldKey),
            id: fieldKey,
            type: isEmailField ? 'email' : 'text',
            required: 'Last Name' === startCase(fieldKey),
            defaultValue,
          });
        }
      });

      if (!includeType) {
        // As the fields are fetched from backend we need an extra check to ensure there is no 'type' field exists.
        const indexToRemove = fields.findIndex((i) => i.id === 'type');
        if (indexToRemove !== -1) {
          fields.splice(indexToRemove, 1);
        }
        return fields;
      }

      const typeExists = fields.some((i) => i.id === 'type');
      return typeExists
        ? fields
        : [
            {
              label: 'Type',
              id: 'type',
              type: 'select',
              defaultValue: startCase(self.type),
              editable: false,
              options: [
                { value: PERSON_TYPE, label: PERSON_TYPE },
                { value: ORGANIZATION_TYPE, label: ORGANIZATION_TYPE },
              ],
            },
            ...fields,
          ];
    },
  }));

const ContactsStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
  })
  .actions((self) => {
    const getCreateFormFields = flow(function* (type, defaultValues = {}) {
      const blackListedLabels = [
        'lastName',
        'firstName',
        'fullName',
        'middleName',
      ];
      let fields = [];

      const defaultFieldKeys = yield contactsService.fetchDefaultContactFields(
        getOrgFprintFromStoreNode(self),
      );
      const allFieldKeys = [...defaultFieldKeys, ...Object.keys(defaultValues)];
      const uniqueFieldKeys = allFieldKeys.filter(
        (item, index) =>
          allFieldKeys.indexOf(_getNormalizedFieldKey(item)) === index,
      );

      const commonFields = uniqueFieldKeys
        .map((fieldKey) => {
          return {
            label: startCase(fieldKey),
            id: fieldKey,
            type: fieldKey.toLowerCase().endsWith('email') ? 'email' : 'text',
          };
        })
        .filter(({ id }) => {
          return blackListedLabels.indexOf(id) < 0;
        });

      if (type === PERSON_TYPE) {
        fields = [
          {
            label: 'Type',
            id: 'type',
            type: 'select',
            defaultValue: PERSON_TYPE,
            validation: () => true,
            options: [
              { value: PERSON_TYPE, label: PERSON_TYPE },
              { value: ORGANIZATION_TYPE, label: ORGANIZATION_TYPE },
            ],
          },
          {
            label: 'Full Name',
            id: 'fullName',
            type: 'text',
          },
          {
            label: 'First Name',
            id: 'firstName',
            type: 'text',
            validation: VALIDATION_TYPES.present,
          },
          {
            label: 'Middle Name',
            id: 'middleName',
            type: 'text',
          },
          {
            label: 'Last Name',
            id: 'lastName',
            required: true,
            type: 'text',
            validation: VALIDATION_TYPES.present,
          },
          ...commonFields,
        ];
      } else if (type === ORGANIZATION_TYPE) {
        fields = [
          {
            label: 'Type',
            id: 'type',
            type: 'select',
            options: [
              { value: PERSON_TYPE, label: 'Person' },
              { value: ORGANIZATION_TYPE, label: 'Organization' },
            ],
          },
          {
            label: 'Name',
            id: 'lastName',
            required: true,
            type: 'text',
            validation: VALIDATION_TYPES.present,
          },
          ...commonFields,
        ];
      }
      const matchedDefaultValues = [];

      // Populate fields with default values
      fields = fields.map((field) => {
        if (defaultValues && defaultValues[field.id]) {
          field.defaultValue = defaultValues[field.id];
          matchedDefaultValues.push(field.id);
        }

        return field;
      });

      return fields;
    });

    const removeContact = (contactId) => {
      return self.removeItem(getContactIdentifier(contactId));
    };

    const deleteContact = flow(function* (contactId) {
      const contact = self.getContact(contactId);
      analyticsService.track('Contact Deleted', {
        contactId: contact.id,
        type: contact.type,
        ...contact.fields,
      });

      try {
        self.removeContact(contactId);
        yield contactsService.deleteContact(
          getOrgFprintFromStoreNode(self),
          contactId,
        );
        return true;
      } catch (error) {
        console.error('Failed to delete contact : ', error);
        if (error && typeof error === 'string') {
          self.error = error;
        }
        return false;
      }
    });

    const updateContact = flow(function* (contactId, properties) {
      const contact = self.getContact(contactId);

      analyticsService.track('Contact Edited', {
        contactId,
        oldProperties: Object.keys(properties).reduce((acc, property) => {
          acc[property] = contact.fields[property];
          return acc;
        }, {}),
        newProperties: properties,
      });

      try {
        yield contact.update(properties);
        return true;
      } catch (error) {
        console.error('Failed to update contact : ', error);
        if (error && typeof error === 'string') {
          self.error = error;
        }
        return false;
      }
    });

    const addContact = (newContact) => {
      const identifier =
        newContact.identifier || getContactIdentifier(newContact.id);
      newContact.identifier = identifier;
      return self.addItem(identifier, newContact);
    };

    const createNewContact = flow(function* (contact) {
      analyticsService.track('Contact Created', { ...contact });
      try {
        const newContact = yield contactsService.createNewContact(
          getOrgFprintFromStoreNode(self),
          contact,
        );

        self.addContact(newContact);
        if (self.list && self.list[0]) {
          self.list[0].unshift(newContact.identifier);
        } else {
          self.list = [[newContact.identifier]];
        }

        return newContact;
      } catch (error) {
        console.error('Failed to create new contact : ', error);
        if (error && typeof error === 'string') {
          self.error = error;
        }
      }
    });

    const fetchContactInSpecificMatter = flow(
      function* fetchContactInSpecificMatter(contactId, matterId) {
        // Get a contact within the context of a matter
        // this is needed for accessing the contact role attribute
        try {
          self.isLoading = true;
          const contact = yield contactsService.fetchContactInSpecificMatter(
            getOrgFprintFromStoreNode(self),
            contactId,
            matterId,
          );
          return self.addContact(contact);
        } catch (error) {
          console.error('Failed to fetch matter specific contact ', error);
          self.isLoading = false;
          return false;
        }
      },
    );

    const fetchContact = flow(function* fetchContact(contactId) {
      try {
        self.isLoading = true;
        const contact = yield contactsService.fetchContact(
          getOrgFprintFromStoreNode(self),
          contactId,
        );
        return self.addContact(contact);
      } catch (error) {
        console.error('Failed to fetch contact ', error);
        self.isLoading = false;
        return false;
      }
    });

    const fetch = flow(function* fetch(query = {}) {
      return yield self.paginate(query);
    });

    const viewContact = (contactId) => {
      analyticsService.track('Contact Viewed', {
        contactId,
      });
      return self;
    };

    return {
      fetchContact,
      addContact,
      getCreateFormFields,
      deleteContact,
      removeContact,
      updateContact,
      createNewContact,
      fetch,
      viewContact,
      fetchContactInSpecificMatter,
    };
  })
  .views((self) => ({
    tableList: () => {
      return self.list.flat().map((contact) => {
        return {
          id: contact.id,
          type: contact.type,
          firstName: contact.fields.firstName || contact.fields.fullName || '',
          lastName: contact.fields.lastName || contact.fields.companyName || '',
          email: contact.fields.email || contact.fields.emailAddress || '',
        };
      });
    },

    formOptions: () => {
      return sortBy(
        self.list.flat().map((contact) => {
          return {
            label: contact.fullName(),
            value: `${contact.id}`,
          };
        }),
        ['label'],
      );
    },

    getContact: (idProp) => {
      return self.getItem(getContactIdentifier(idProp));
    },
  }));

const PaginatedContactsStore = createPaginatedModel(
  'PaginatedContacts',
  ContactsStore,
  ContactModel,
  { paginate: contactsService.getContacts },
);

export { ContactModel, PaginatedContactsStore as default };

/* Libraries */
import React, { useEffect, useState } from 'react';
import moment from 'moment';

/* Components */
import DayPicker from 'react-day-picker';

import Dropdown from '~/src/components/Dropdown';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { css } from 'aphrodite';
import { isDateValid } from '~/src/utils/validation';
import { getLocalDateFormat } from '~/src/utils/date';
import TextInput from '~/src/components/Inputs/TextInput';
import SelectInput from '~/src/components/Inputs/SelectInput';

import styles from './styles';

const NavBar = ({ date, onYearChange, onMonthChange }) => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1em',
    marginBottom: 0,
    marginTop: '0.5em',
  };

  const yearOptions = [];
  const monthOptions = [
    { label: 'January', value: '0' },
    { label: 'February', value: '1' },
    { label: 'March', value: '2' },
    { label: 'April', value: '3' },
    { label: 'May', value: '4' },
    { label: 'June', value: '5' },
    { label: 'July', value: '6' },
    { label: 'August', value: '7' },
    { label: 'September', value: '8' },
    { label: 'October', value: '9' },
    { label: 'November', value: '10' },
    { label: 'December', value: '11' },
  ];

  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const startYear = 1800;
  const endYear = 2099;
  const numberOfYearOptions = endYear - startYear;

  for (let i = 0; i <= numberOfYearOptions; i += 1) {
    yearOptions.push({
      label: `${startYear + i}`,
      value: `${startYear + i}`,
    });
  }

  const handleMonthChange = (month) => {
    onMonthChange(month);
  };

  const handleYearChange = (year) => {
    onYearChange(year);
  };

  return (
    <div style={containerStyle}>
      <div style={{ display: 'flex' }}>
        <div
          style={{ width: '100px', marginRight: '15px' }}
          onClick={(e) => e.preventDefault()}
        >
          <SelectInput
            options={monthOptions}
            value={`${monthOptions[currentMonth].value}`}
            noMargin={true}
            noDropdownIcon={true}
            onChange={handleMonthChange}
          />
        </div>
        <div style={{ width: '75px' }} onClick={(e) => e.preventDefault()}>
          <SelectInput
            options={yearOptions}
            value={`${currentYear}`}
            noMargin={true}
            noDropdownIcon={true}
            onChange={handleYearChange}
          />
        </div>
      </div>
      <div
        style={{
          width: '40px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <a>
          <ArrowLeft
            size={17}
            onClick={() =>
              handleMonthChange(currentMonth === 0 ? 11 : currentMonth - 1)
            }
          />
        </a>
        <a>
          <ArrowRight
            size={17}
            onClick={() =>
              handleMonthChange(currentMonth === 11 ? 0 : currentMonth + 1)
            }
          />
        </a>
      </div>
    </div>
  );
};

const DatePickerInput = React.forwardRef(
  (
    {
      id,
      style,
      className,
      LeftIcon,
      placeholder,
      label,
      labelCssStyle,
      value,
      hint,
      count,
      error,
      required,
      noMargin,
      dateFormat,
      onChange,
    },
    ref,
  ) => {
    let hideDropdownCallback;

    const parseDate = (date) => {
      if (isDateValid(date)) return new Date(date);
      return new Date();
    };

    const [currentDate, setCurrentDate] = useState(parseDate(value));

    useEffect(() => {
      setCurrentDate(parseDate(value));
    }, [value]);

    const renderTrigger = ({ hideDropdown, showDropdown }) => {
      hideDropdownCallback = hideDropdown;

      const handleClick = (event) => {
        event.preventDefault();
        showDropdown();
      };

      return (
        <TextInput
          ref={ref}
          id={id}
          style={style}
          className={className}
          LeftIcon={LeftIcon}
          placeholder={dateFormat || placeholder}
          label={label}
          labelCssStyle={labelCssStyle}
          cssStyle={labelCssStyle}
          autoComplete={'date'}
          value={value}
          type={'text'}
          hint={hint}
          count={count}
          error={error}
          editable={true}
          required={required}
          noMargin={noMargin}
          onChange={onChange}
          onFocus={showDropdown}
          onClick={handleClick}
        />
      );
    };

    const modifiers = {
      selected: currentDate,
    };

    const handleDayClick = (date) => {
      const value = moment(date).format(dateFormat);
      onChange(value);
      setCurrentDate(date);
      hideDropdownCallback();
    };

    const handleDateChange = (nextDate) => {
      if (nextDate) {
        setCurrentDate(nextDate);
      }
    };

    const handelMonthChange = (nextMonth) => {
      const year = currentDate.getFullYear();
      const nextDate = new Date(year, nextMonth);
      setCurrentDate(nextDate);
    };

    const handelYearChange = (nextYear) => {
      const month = currentDate.getMonth();
      const nextDate = new Date(nextYear, month);
      setCurrentDate(nextDate);
    };

    return (
      <Dropdown
        show={true}
        horizontalPosition={'left'}
        renderTrigger={renderTrigger}
        className={`${css(styles.label)}`}
      >
        <DayPicker
          fixedWeeks
          month={currentDate}
          modifiers={modifiers}
          navbarElement={
            <NavBar
              date={currentDate}
              onChange={handleDateChange}
              onYearChange={handelYearChange}
              onMonthChange={handelMonthChange}
            />
          }
          onDayClick={handleDayClick}
          captionElement={() => ''}
        />
      </Dropdown>
    );
  },
);

DatePickerInput.defaultProps = {
  dateFormat: getLocalDateFormat(),
};

export default DatePickerInput;

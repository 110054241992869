import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { QueryClientProvider } from 'react-query';
import bugsnag from '~/src/utils/bugsnag';

import store from '~/src/stores';

import {
  LayoutProvider,
  SentryProvider,
  AnalyticsProvider,
  RouterAnalytics,
  InterceptorProvider,
  ToastProvider,
} from '~/src/contexts';

import App from '~/src/components/App';
import AuthContainer from '~/src/containers/Auth';
import Routes from '~/src/components/Routes';
import WebViewerContextProvider from '~/src/components/Webviewer/WebViewerContextProvider';
import { MuiBase } from '../theme/MuiBase';
import { history } from '../utils/history';
import { queryClient } from '../utils/queryClient';
import { UIFlagsProvider } from '../entities/uiFlags';
import DelightedNpsIntegration from '../components/DelightedNpsIntegration/DelightedNpsIntegration';

const AppContainer = () => {
  const BugsnagErrorBoundary =
    bugsnag.ErrorBoundary as React.ComponentType<any>;
  return (
    <MuiBase>
      <BugsnagErrorBoundary>
        <Sentry.ErrorBoundary showDialog>
          <QueryClientProvider client={queryClient}>
            <ToastProvider>
              <Provider store={store}>
                <InterceptorProvider>
                  <DndProvider backend={Backend}>
                    <Router history={history}>
                      <AuthContainer>
                        <UIFlagsProvider>
                          <SentryProvider>
                            <DelightedNpsIntegration />
                            <AnalyticsProvider>
                              <RouterAnalytics>
                                <LayoutProvider>
                                  <App>
                                    <WebViewerContextProvider>
                                      <Routes />
                                    </WebViewerContextProvider>
                                  </App>
                                </LayoutProvider>
                              </RouterAnalytics>
                            </AnalyticsProvider>
                          </SentryProvider>
                        </UIFlagsProvider>
                      </AuthContainer>
                    </Router>
                  </DndProvider>
                </InterceptorProvider>
              </Provider>
            </ToastProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </BugsnagErrorBoundary>
    </MuiBase>
  );
};

export default AppContainer;

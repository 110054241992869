/* Libaries */
import { filter, startCase } from 'lodash';
import { types, flow, getRoot, destroy } from 'mobx-state-tree';
import { getFormattedDateFromIsoString } from '~/src/utils/date';

/* Services */
import matterService from '~/src/services/matter';

/* Models */
import { QuestionnaireResponse } from '~/src/stores/questionnaireResponsesStore';
import { ContactModel } from '~/src/stores/contactsStore';
import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';
import { Project } from '~/src/stores/projectsStore';

/* Utilities */
import { removeListItem } from '~/src/utils/list';
import { getMatterIdentifier } from '~/src/utils/dataTransformers';
import { getOrgFprintFromStoreNode } from './utils';
import analyticsService from '../services/analytics';

/* Constants */
const STATE_IDLE = 'PENDING';
const STATE_LOADING = 'LOADING';
const STATE_SUCCESS = 'SUCCESS';
const STATE_ERROR = 'ERROR';

export const Matter = types
  .model('Matter', {
    createdAt: types.string,
    createdBy: types.maybeNull(types.string),
    id: types.number,
    identifier: types.identifier,
    description: types.maybeNull(types.string),
    externalMatterId: types.maybeNull(types.string),
    lastModified: types.maybeNull(types.string),
    lastSynced: types.maybeNull(types.string),
    matterNumber: types.maybeNull(types.number),
    documentCount: types.optional(types.number, 0),
    organizationId: types.maybeNull(types.number),
    title: types.string,
    details: types.maybeNull(
      types.model({
        data: types.frozen(),
        v2Data: types.optional(types.maybeNull(types.frozen())),
        relatedContacts: types.optional(
          types.array(types.reference(ContactModel)),
          [],
        ),
        documentSets: types.optional(
          types.array(
            types.maybeNull(types.late(() => types.reference(Project))),
          ),
          [],
        ),
        documents: types.optional(
          types.array(
            types.maybeNull(types.late(() => types.reference(Project))),
          ),
          [],
        ),
        questionnaireResponses: types.optional(
          types.array(types.reference(QuestionnaireResponse)),
          [],
        ),
      }),
    ),
  })
  .actions((self) => {
    const fetchDetails = flow(function* fetchDetails() {
      const { details, projects } = yield matterService.getMatterDetail(
        getOrgFprintFromStoreNode(self),
        self.id,
      );

      const root = getRoot(self);
      root.projects.addAllProjects(projects);

      self.details = details;
      return self;
    });

    const update = flow(function* update(newProperties) {
      const res = yield matterService.updateMatter(
        getOrgFprintFromStoreNode(self),
        self.id,
        newProperties,
      );
      Object.assign(self, res);
      return self;
    });

    const removeDocument = (id) => {
      const parsedId = parseInt(id);
      if (
        self.details &&
        self.details.documents &&
        self.details.documents.length > 0
      ) {
        self.details.documents = removeListItem({
          item: { id: parsedId },
          key: 'id',
          list: self.details.documents,
        });
      }
    };

    const removeDocumentSet = (id) => {
      const parsedId = parseInt(id);
      if (
        self.details &&
        self.details.documentSets &&
        self.details.documentSets.length > 0
      ) {
        self.details.documentSets = removeListItem({
          item: { id: parsedId },
          key: 'id',
          list: self.details.documentSets,
        });
      }
    };

    return {
      removeDocument,
      removeDocumentSet,
      fetchDetails,
      update,
    };
  })
  .views((self) => {
    const summaryFields = () => {
      const matter = self;
      const fields = [];

      if (matter.title) {
        fields.push({
          hint: 'Name',
          id: 'name',
          type: 'staticText',
          defaultValue: matter.fullTitle(),
        });
      }

      if (matter.description) {
        fields.push({
          hint: 'Description',
          id: 'description',
          type: 'staticText',
          defaultValue: matter.description,
        });
      }

      if (matter.createdBy) {
        fields.push({
          hint: 'Created By',
          id: 'createdBy',
          type: 'staticText',
          defaultValue: matter.createdBy,
        });
      }

      if (matter.createdAt) {
        fields.push({
          hint: 'Creation Date',
          id: 'createdAt',
          type: 'staticText',
          defaultValue: getFormattedDateFromIsoString(matter.createdAt),
        });
      }

      return fields;
    };

    const relatedContactsFields = () => {
      if (!self.details || !self.details.relatedContacts) {
        return [];
      }
      const fields = self.details.relatedContacts.map((contact) => ({
        hint: startCase(contact.role),
        key: contact.identifier,
        id: contact.id,
        type: 'staticLink',
        defaultValue: contact.fullName(),
        contact: contact.toJSON(),
      }));

      return fields;
    };

    const getEntityCardFields = () => {
      const cards = {};
      const detailsData = self.details.v2Data || self.details.data;

      Object.keys(detailsData).forEach((key) => {
        const deconstructedKey = key.split('.');
        const entity = deconstructedKey[0];
        const attribute = deconstructedKey[1];
        const value = detailsData[key];

        if (!cards[entity]) {
          cards[entity] = [];
        }

        if (value || value === '') {
          // value is allowed to be empty string
          cards[entity].push({
            hint: startCase(attribute),
            id: attribute,
            type: 'staticText',
            defaultValue: value.toString(),
          });
        }
      });

      return cards;
    };

    const attachedDocumentTableList = () => {
      return self.details.documents.map((document) => ({
        id: document.id,
        fprint: document.fprint,
        title: document.title,
        updatedAt: document.updatedAt,
        docx: document.docx,
      }));
    };

    const attachedDocumentSetTableList = () => {
      return self.details.documentSets.map((documentSet) => ({
        id: documentSet.id,
        fprint: documentSet.fprint,
        title: documentSet.name,
        updatedAt: documentSet.updatedAt,
        documents: documentSet.documents,
        numDocs: documentSet.documents ? documentSet.documents.length : 0,
      }));
    };

    const fullTitle = () =>
      self.matterNumber ? `${self.matterNumber} - ${self.title}` : self.title;

    return {
      fullTitle,
      getEntityCardFields,
      attachedDocumentTableList,
      attachedDocumentSetTableList,
      relatedContactsFields,
      summaryFields,
    };
  });

export const MatterStore = types
  .model('MatterStore', {
    error: types.maybeNull(types.string),
    state: types.optional(types.string, STATE_IDLE),
  })
  .actions((self) => {
    const createNewMatter = flow(function* createNewMatter(name) {
      const nextMatter = yield matterService.createNewMatter(
        getOrgFprintFromStoreNode(self),
        name,
      );
      analyticsService.track('Matter Created', {
        name,
      });
      const identifier = self.setMatter(nextMatter);
      return identifier;
    });

    const deleteMatter = flow(function* deleteMatter(matterId) {
      try {
        yield matterService.deleteMatter(
          getOrgFprintFromStoreNode(self),
          matterId,
        );

        for (let page = 0; page < self.list.length; page += 1) {
          const pageList = self.list[page];
          self.list[page] = filter(pageList, (matter) => {
            if (matterId === matter.id) {
              return false;
            }

            return true;
          });
        }

        const matter = self.getMatter(matterId);
        destroy(matter);
        analyticsService.track('Matter Deleted', {
          matterId,
        });

        return true;
      } catch (error) {
        console.error('Failed to delete matter', error);
        self.error = error;
        return false;
      }
    });

    const updateMatter = flow(function* updateMatter(
      matterId,
      propertiesToUpdate,
    ) {
      try {
        self.state = STATE_LOADING;
        const matter = self.getMatter(matterId);

        yield matter.update(propertiesToUpdate);

        self.state = STATE_SUCCESS;

        analyticsService.track('Matter Updated', {
          matterId,
          oldName: matter.title,
          newName: propertiesToUpdate.title,
        });
      } catch (error) {
        if (error && typeof error === 'string') {
          self.error = error;
        }
        self.state = STATE_ERROR;
      }
    });

    const setMatter = (matter) => {
      const identifier = self.addItem(matter.identifier, matter);
      if (self.list) {
        if (self.list[0]) {
          self.list[0].unshift(identifier);
        } else {
          self.list[0] = [identifier];
        }
      }
      return identifier;
    };

    const fetch = flow(function* (query = {}) {
      return yield self.paginate(query);
    });

    const viewMatter = (matterId) => {
      analyticsService.track('Matter Viewed', {
        matterId,
      });
      return self.getMatter(matterId);
    };

    const deleteDocument = (id) => {
      self.dictionary.forEach((matter) => {
        matter.removeDocument(id);
      });
    };

    const deleteDocumentSet = (id) => {
      self.dictionary.forEach((matter) => {
        matter.removeDocumentSet(id);
      });
    };

    return {
      fetch,
      setMatter,
      deleteDocument,
      deleteDocumentSet,
      createNewMatter,
      updateMatter,
      deleteMatter,
      viewMatter,
    };
  })
  .views((self) => {
    const success = () => {
      return self.state === STATE_SUCCESS;
    };

    const getMatter = (id) => {
      const identifier = getMatterIdentifier(id);
      return self.getItem(identifier);
    };

    const selectOptions = () => {
      return self.list.flat().map((matter) => ({
        value: `${matter.id}`,
        label: matter.fullTitle(),
      }));
    };

    const tableList = () => {
      return self.list.flat().map((matter) => {
        return {
          id: matter.id,
          name: matter.title,
          number: matter.matterNumber,
          title: matter.title,
          numberOfDocuments: `${matter.documentCount}`,
        };
      });
    };

    return {
      tableList,
      success,
      getMatter,
      selectOptions,
    };
  });

const PaginatedMatterStore = createPaginatedModel(
  'PaginatedMatterStore',
  MatterStore,
  Matter,
  { paginate: matterService.getMatters },
);

export default PaginatedMatterStore;

import { useMemo } from 'react';
import { useSubscription } from '~/src/entities/subscription';
import { canOrgUseFeatureAccess, getOrg } from '~/src/entities/user';

export const useContentPackageWizardRedirect = () => {
  const { data: subscriptionData } = useSubscription();
  const org = getOrg();
  const currentPath = window.location.pathname;

  const shouldRedirect = useMemo(() => {
    if (!subscriptionData) return false;

    if (
      currentPath.includes('content-package-wizard') ||
      currentPath.includes('welcome')
    ) {
      return false;
    }

    const isCbsSubscription = canOrgUseFeatureAccess('CBS_INTEGRATION', org);
    const contentPackagesWizardRan =
      subscriptionData.content_packages_wizard_ran;
    const contentPackagesPurchased =
      subscriptionData.content_packages_purchased;

    return (
      isCbsSubscription &&
      contentPackagesWizardRan === false &&
      contentPackagesPurchased > 0
    );
  }, [subscriptionData, currentPath, org]);

  return shouldRedirect;
};

export default useContentPackageWizardRedirect;

import { types } from 'mobx-state-tree';
import { getFormattedFullDateFromIsoString } from '~/src/utils/date';

export const OrganizationCoupon = types.model({
  couponId: types.maybeNull(types.string),
  couponDuration: types.maybeNull(types.string),
  couponDurationInMonths: types.maybeNull(types.string),
  couponMaxRedemptions: types.maybeNull(types.number),
  couponPercentOff: types.maybeNull(types.number),
  couponValid: types.maybeNull(types.boolean),
  couponStart: types.maybeNull(types.string),
  couponEnd: types.maybeNull(types.string),
});

export const OrganizationReceipt = types.model({
  created: types.maybeNull(types.integer),
  amount_captured: types.optional(types.integer, 0),
  reciept_url: types.optional(types.string, ''),
});

export const OrganizationInvoice = types.model({
  date: types.optional(types.string, ''),
  total: types.optional(types.integer, 0),
  invoice_pdf: types.optional(types.string, ''),
});

export const OrganizationSubscription = types
  .model({
    plan: types.model({
      amount: types.maybeNull(types.number),
      name: types.string,
      id: types.string,
      interval: types.string,
    }),
    canceledAt: types.maybeNull(types.string),
    ccId: types.maybeNull(types.string),
    currentPeriodEnd: types.maybeNull(types.string),
    quantity: types.maybeNull(types.integer),
    paidQuantity: types.maybeNull(types.integer),
    status: types.enumeration([
      'active',
      'canceled',
      'past_due',
      'trialing',
      'unpaid',
    ]),
    nextInvoice: types.optional(OrganizationInvoice, {}),
    trialStart: types.maybeNull(types.integer),
    trialEnd: types.maybeNull(types.integer),
    isActiveFreeTrial: types.maybeNull(types.boolean),
  })
  .views((self) => {
    const remainingDaysFromCanceledDate = () => {
      if (!self.currentPeriodEnd) {
        return -1;
      }
      const currentDate = Date.now();
      const diffTime = new Date(self.currentPeriodEnd).valueOf() - currentDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    const isStatusPastDue = () => self.status === 'past_due';

    const isStatusCanceled = () => self.status === 'canceled';

    const isCanceled = () => {
      return !!self.canceledAt || isStatusCanceled();
    };

    const freeTrialIsActive = () => {
      return self.isActiveFreeTrial; // true, false
    };

    const remainingDaysOfFreeTrial = () => {
      if (!self.trialEnd) return 0;
      const currentDate = Date.now();
      const diffTime = self.trialEnd - currentDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    const nextInvoiceEndFormatted = () => {
      if (!self.currentPeriodEnd) {
        return null;
      }

      return getFormattedFullDateFromIsoString(self.currentPeriodEnd);
    };

    return {
      remainingDaysFromCanceledDate,
      isCanceled,
      isStatusCanceled,
      isStatusPastDue,
      freeTrialIsActive,
      remainingDaysOfFreeTrial,
      nextInvoiceEndFormatted,
    };
  });

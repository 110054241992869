import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite';
import { Form, renderFormField } from '~/src/components/Forms';
import modalStyles from '~/src/components/Modal/styles';
import {
  COURT_FORM_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
} from '~/src/utils/constants';

export const ChoosePlanForm = ({
  onChange,
  cycle,
  courtFormPlans,
  documentAutomationPlans,
  defaultSeats,
}) => {
  const [generalCourtFormPrice, setGeneralCourtFormPrice] = useState(0);
  useEffect(() => {
    setGeneralCourtFormPrice(
      COURT_FORM_PLANS?.[cycle]?.[COURT_FORM_PLANS?.[cycle].length - 1]?.price,
    );
  }, [cycle]);

  const generalCourtFormCycle = cycle === 'annual' ? 'year' : 'month';
  const fields = [
    {
      id: 'seats',
      label: 'Number of seats',
      type: 'incrementer',
      minValue: 1,
      maxValue: 250,
      defaultValue: defaultSeats,
      validation: () => true,
    },
    {
      id: 'plan',
      label: 'Plan',
      type: 'planChoice',
      options: [
        {
          label: 'Fillable court forms',
          plans: COURT_FORM_PLANS[cycle],
          defaultValue: courtFormPlans,
          subHeading: `Additional libraries are $${generalCourtFormPrice}/${generalCourtFormCycle}`,
        },
        {
          label: 'Word document automation',
          plans: DOCUMENT_AUTOMATION_PLANS[cycle],
          defaultValue: [documentAutomationPlans],
        },
        {
          label: 'e-signatures',
          subLabel: 'Included for free',
          plans: [],
        },
      ],
      validation: () => true,
    },
    {
      id: 'cycle',
      label: 'Billing cycle',
      type: 'multipleChoice',
      defaultValue: 'monthly', // defaultValue: cycleValue,

      options: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Annual', value: 'annual' },
      ],
      validation: () => true,
    },
  ];

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <div>
      <div className={css(modalStyles.modalBody)}>
        <Form fields={renderFields} onChange={onChange} />
      </div>
    </div>
  );
};
